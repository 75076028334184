<template>

     <div id="header" class="">


          <a class="button"  @click="$setActiveProperty('panel1', 'FilterHome')">Filter</a>


          <a class="settings" @click="$setActiveProperty('panel1', 'UserHome')">{{ initials }}</a>
     </div>

</template>


<script>

import { mapState } from 'vuex'





export default{

     data(){
          return {

          }
     },

     components: {
		
     },
     computed:{
          ...mapState(['user']),

          initials(){


               if (this.user.name){

                    const words = this.user.name.split(' ');
                    const initials = words[0][0].toUpperCase() + words[words.length - 1][0].toUpperCase();
                    return initials;
               } else {

                    return '';
               }
               


          }



     },



     methods: {




     },

     watch: {



     },
}
</script>
<style scoped>

#header{
position: fixed;
top: 8px;
left: 8px;
right: 8px;
z-index: 99;
height: 48px;
background: var(--wit);
}

.settings{
	position: absolute;
	right: 16px;
	top: 0px;
     background: var(--blauw);
     color: #fff;
     width: 40px;
     height: 40px;
     border-radius: 20px;  
     font-size: 16px;
     font-weight: 400;
     text-align: center;
     padding-top: 8px;
     box-sizing: border-box;
     cursor: pointer;
}



</style>
