<template>

	<transition name="slide-up">

		<div class="panel autoheight" v-if="this.active.panel3=='GigHomeCreateBands'" >
               
               <div class="debug">GigHomeCreateBands</div>

			<div class="header">
				<a class="right" @click="$setActiveProperty('panel3', '')"><DownIcon/></a>
				<div class="title">Select Band</div>
			</div>


               <div class="content">


                    <div v-for="band in bandsOwnedByUser" :key="band.id">

                         <div class="button grey w100 mb8" @click="handleBandClick(band)">
                              {{band.name}} 
                         </div>

                    </div>
                    
                    
    

               </div>

		</div>

	</transition>

</template>


<script>


import { mapState } from 'vuex';
import DownIcon from '@/components/common/icons/DownIcon.vue';

export default{

data(){
     return {
          
     }
},

components: {

     DownIcon

},
computed:{

	...mapState(['active','bands']),

     bandsOwnedByUser(){

          return this.bands.filter(band => band.role === 'owner');


     },




},



methods: {


     handleBandClick(band){

          this.$setActiveProperty('bandId', band.id);
          this.$setActiveProperty('formBand', band);
          this.$setActiveProperty('panel3', '');

     }




},

watch: {



},
}
</script>

<style scoped>



</style>
