<template>

	<transition name="slide-right">

		<div class="panel" v-if="this.active.panel4=='UserHomeBandsBandSettings'" >

			<div class="debug">UserHomeBandsBandPersonal</div>

			<div class="header">

                    <a class="left" @click="$setActiveProperty('panel4', '')"><LeftIcon/></a>
                    <div class="title center">{{band.name}}</div>


               </div>

               <div class="content">


                    <table class="form">

                         <tr>
                              <td>De admin van {{band.name}} mag mijn agenda bekijken.</td>
                              <td align="right"><Toggle v-model="read" /></td>
                         </tr>

                         <tr>
                              <td>De admin van {{band.name}} mag mijn agenda bewerken.</td>
                              <td align="right"><Toggle v-model="write" /></td>
                         </tr>


                    </table>

                    {{  }}

                    


               </div>

		

		</div>

	</transition>

</template>


<script>

import axios from 'axios';
import Toggle from '@vueform/toggle'
import LeftIcon from '@/components/common/icons/LeftIcon.vue';

// https://github.com/vueform/toggle

import { mapState } from 'vuex';


export default{

data(){
     return {
		read: false,
          write: false
     }
},

components: {
     Toggle,
     LeftIcon
},     
computed:{

	...mapState(['active','config','user','band']),

     userChanges(){


          return this.user;

          
     }


},



methods: {


	async submit() {

		var post = {};

		post.task = 'setPermissions';
		post.usertoken = this.active.usertoken;
          post.type = 'gigs';
          post.bandId = this.band.id;
          post.write = this.write;
          post.read = this.read;
		
		

		console.log(post);

          this.$setActiveProperty('loading',true);

		axios.post(this.config.apiserver + 'users', post )

		.then(() => {

               this.$setActiveProperty('loading',false);

			// console.log(response.data);





			this.$store.dispatch('fetchUser');

			


		})
		.catch(error => {
		console.error(error);



		});
	},

     setpermissions(){


          // De permissions zitten in het user object, als de user geupdates wordt, of de band wisselt, de permissions opnieuw laden.


          try {

               const permission = this.user.permissions.find(permission => permission.band_id === this.band.id);


               if (permission.write==1){
               
                    this.write = true;
                 
               } else {

                    this.write = false;
               }


               if (permission.read==1){
               
                    this.read = true;
            
               } else {

                    this.read = false;
               }


          } catch (error) {
               return false;
          }    



     }





},

watch: {

     write() {
          this.submit();
     },
     read() {
          this.submit();
     },

     'user.permissions': {

          handler() {

               if(this.active.panel4=='UserHomeBandsBandSettings'){

                    this.setpermissions();

               }

          
          },
          deep: true,
          immediate: true,
     },
     'band.id': {
          handler() {
               
               if(this.active.panel4=='UserHomeBandsBandSettings'){

                    this.setpermissions();

               }
          },
          deep: true,
          immediate: true,
     }



},
}
</script>


<style src="@vueform/toggle/themes/default.css"></style>

<style scoped>

</style>
