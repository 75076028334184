<template>

     <div class="screen" @mouseover="handleDayClick(dayNumber)" @touchstart="handleDayClick(dayNumber)">


          <div class="uppercase big mb8">{{ day.dayname }} {{ day.day }} {{ getmonth(day.month) }}</div>

   

          <div class="gigs" v-for="gig in day.gigs" :key="gig.id">


               <div @click="handleGigClick(gig)">


                    <div class="gig band" v-if="gig.sort=='band'">

                         <div>

                              <span  class=" " v-if="gig.dayType=='first'">{{ gig.starttime }}</span>
                              <span  class="" v-if="gig.dayType=='last'">tot {{ gig.endtime }}</span>
                              <span  class="" v-if="gig.dayType=='middle'">hele dag</span>
                              <span  class=" small" v-if="gig.dayType=='singleDay'">{{ gig.starttime }}-{{ gig.endtime }}</span>



                              <span class=" small fr">{{ updated(gig) }}</span>
                         </div>

            

                         <div>

                              <span class="bold">{{gig.band.name}}</span>
                         </div>

                         <div>

                              <span class="">{{gig.venue}} {{gig.city}}</span>

                         </div>

                         <div>
                              <span class=" small">{{gig.type.name}}</span>
                              <span class=" small fr">{{gig.status.name}}</span>
                              
                         </div>

                         

                         
                                   
     

                    </div>


               </div>







          </div>



          <div class="gigs" v-for="gig in day.gigs" :key="gig.id">


               <div @click="handleGigClick(gig)">

                    <div class="gig appointment" v-if="gig.sort=='appointment'">

                         <span v-for="band in gig.ownersbands" :key="band.id" :class="['bullit', band.color]">
                              {{ band.name[0] }}
                         </span>

                         <span class="bold" v-if="gig.user.name">{{gig.user.name}}&nbsp;</span>
                         <span class="bold" v-if="!gig.user.name">{{gig.user.email}}&nbsp;</span>

                         <span  class=" " v-if="gig.dayType=='first'">vanaf {{ gig.starttime }} </span>
                         <span  class=" " v-if="gig.dayType=='last'">tot {{ gig.endtime }} </span>
                         <span  class=" " v-if="gig.dayType=='singleDay'">{{ gig.starttime }} </span>

                         &nbsp;<span class="">{{gig.venue}}</span>



                    </div>




               </div>







               </div>




     </div>

</template>


<script>


import { mapState } from 'vuex';


export default{

data() {
     return {

     };
},

props: {
     dayNumber: Number,
},



computed:{

     ...mapState(['active','gigsbyday','translate','user']),




     day(){

          const dayNames = ['MON_SHORT', 'TUE_SHORT', 'WED_SHORT', 'THU_SHORT', 'FRI_SHORT', 'SAT_SHORT', 'SUN_SHORT'];

          const formattedMonth = this.active.currentMonth.toString().padStart(2, '0');
          const formattedDay = this.dayNumber.toString().padStart(2, '0');
          const dayKey = `${this.active.currentYear}-${formattedMonth}-${formattedDay}`;
          var gigs = '';



          const date = new Date(this.active.currentYear, this.active.currentMonth, this.active.currentDay);
          var dayname = dayNames[date.getDay()];

          dayname = this.translate[dayname][this.active.language];

          if (this.gigsbyday[dayKey]) {
              gigs = this.gigsbyday[dayKey];
          }

          const day = {
               dayname: dayname,
               day: this.dayNumber,
               month: this.active.currentMonth,
               year: this.active.currentYear,
               gigs: gigs,
          };



          return day;


     }



},



methods: {


     handleGigClick(gig){

          this.$setActiveProperty('gigId', gig.id);


          if (gig.sort=='appointment'){

               if (gig.user.id == this.user.id) {

                    this.$setActiveProperty('panel1', 'AppointmentHome');

               }    
          }


          if (gig.sort=='band'){


               this.$setActiveProperty('panel1', 'GigHome');
          }

     },

     handleDayClick()
     {

          var date = new Date(Date.UTC(this.active.currentYear, this.active.currentMonth-1, this.active.currentDay));
          date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
          const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
          const weekNo = Math.ceil(((date - yearStart) / 86400000 + 1) / 7);


          this.$setActiveProperty('gotoWeek', weekNo);


     },
     getmonth(month){

     if (month==1){ return this.translate.JAN_SHORT[this.active.language]; }
     if (month==2){ return this.translate.FEB_SHORT[this.active.language]; }
     if (month==3){ return this.translate.MAR_SHORT[this.active.language]; }
     if (month==4){ return this.translate.APR_SHORT[this.active.language]; }
     if (month==5){ return this.translate.MAY_SHORT[this.active.language]; }
     if (month==6){ return this.translate.JUN_SHORT[this.active.language]; }
     if (month==7){ return this.translate.JUL_SHORT[this.active.language]; }
     if (month==8){ return this.translate.AUG_SHORT[this.active.language]; }
     if (month==9){ return this.translate.SEP_SHORT[this.active.language]; }
     if (month==10){ return this.translate.OCT_SHORT[this.active.language]; }
     if (month==11){ return this.translate.NOV_SHORT[this.active.language]; }
     if (month==12){ return this.translate.DEC_SHORT[this.active.language]; }

     return month;
     },

     updated(gig){

          var updated = gig.updated_at;

          const now = new Date();
          const pastDate = new Date(updated);
          const diffInSeconds = (now - pastDate) / 1000;

          const formatter = new Intl.RelativeTimeFormat('nl', { numeric: 'auto' });

          // Bereken het verschil in verschillende eenheden
          const secondsPerMinute = 60;
          const secondsPerHour = secondsPerMinute * 60;
          const secondsPerDay = secondsPerHour * 24;
          const secondsPerWeek = secondsPerDay * 7;
          const secondsPerMonth = secondsPerDay * 30;
          const secondsPerYear = secondsPerDay * 365;

          // Bepaal en formatteer de tijdeenheid naar behoefte
          if (diffInSeconds < secondsPerMinute) {
          return formatter.format(-Math.floor(diffInSeconds), 'second');
          } else if (diffInSeconds < secondsPerHour) {
          return formatter.format(-Math.floor(diffInSeconds / secondsPerMinute), 'minute');
          } else if (diffInSeconds < secondsPerDay) {
          return formatter.format(-Math.floor(diffInSeconds / secondsPerHour), 'hour');
          } else if (diffInSeconds < secondsPerWeek) {
          return formatter.format(-Math.floor(diffInSeconds / secondsPerDay), 'day');
          } else if (diffInSeconds < secondsPerMonth) {
          return formatter.format(-Math.floor(diffInSeconds / secondsPerWeek), 'week');
          } else if (diffInSeconds < secondsPerYear) {
          return formatter.format(-Math.floor(diffInSeconds / secondsPerMonth), 'month');
          } else {
          return formatter.format(-Math.floor(diffInSeconds / secondsPerYear), 'year');
          }



     },


},


}
</script>

<style scoped>

.screen{
background: var(--grey-2);
padding: 8px;
}     


.gig{

background: var(--grey-1);    
padding: 16px;
margin-bottom: 16px;
border-radius: var(--border-radius-small); 
margin-bottom: 8px;
}

.bullit{
background: var(--grey-3);
color: var(--white);
font-weight: 500;
font-size:12px;
line-height: 9px;
height: 16px;
width: 16px;
padding-top: 3px;
padding-left: 0px;
border-radius: 8px;
text-align: center;
display: inline-block;
box-sizing: border-box;
position: relative;
top: -2px;
margin-right: 2px;
}






</style>
