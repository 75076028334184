<template>

	<transition name="slide-right">

		<div class="panel" v-if="this.active.panel2=='UserHomeAccount'" >

			<div class="debug">UserHomeAccount</div>

			<div class="header">
				<a class="left" @click="$setActiveProperty('panel2', '')"><LeftIcon/></a>

				<div class="title center">Your Account</div>
				

			</div>


			<div class="content">


                    <div class="formgroup" :class="{ editing: isEditing }">
                         <div class="label">Name</div>
                         <div class="inputgroup">
                         <input  v-model="localuser.name" type="text" :disabled="!isEditing">

                         </div>


                    </div>



				<div class="formgroup" :class="{ editing: isEditing }">
                    
                         <div class="inputgroup">

						<span v-if="!isEditing" class="mr8">

							<span v-if="localuser.settings.darktheme">
								<CheckedIcon/>
							</span>


							<span v-else>
								<UncheckedIcon/>
							</span>     

						</span>


						
						<span>Dark theme</span>


						<span class="right"><Toggle v-if="isEditing"  v-model="localuser.settings.darktheme" /></span>
						
						



                         </div>


                    </div>

				


				



				<a class="button bottomright" v-if="!isEditing" @click="isEditing = true">Edit</a>
				<a class="button bottomright" v-if="isEditing" @click="submit()">Save</a>



			</div>



		</div>

	</transition>

</template>


<script>

import Toggle from '@vueform/toggle';

import { mapState } from 'vuex';
import axios from 'axios';

import LeftIcon from '@/components/common/icons/LeftIcon.vue';
import CheckedIcon from '@/components/common/icons/CheckedIcon.vue';
import UncheckedIcon from '@/components/common/icons/UncheckedIcon.vue';



export default{

data(){
     return {
		username: '',
		localuser: null,
		isEditing: false,
     }
},

components: {
	LeftIcon,
	Toggle,
	CheckedIcon,
	UncheckedIcon
},
computed:{

	...mapState(['active','config','user']),






},



methods: {



	submit(){

  

	if (this.config.debug==1){ console.log('updateUser'); }


	const darktheme = this.localuser.settings.darktheme;

	console.log('darktheme' + darktheme);


	let settings = {
	darktheme: darktheme,
	
	};

	let json = JSON.stringify(settings);



	var post = {};

	post.task = 'updateUser';
	post.usertoken = this.active.usertoken;
	post.username = this.localuser.name;
	post.settings = json;

	this.isEditing = false;


	console.log(post);

	this.$setActiveProperty('loading',true);

	axios.post(this.config.apiserver + 'users', post )

	.then(() => {

		// console.log(response.data);

		this.$setActiveProperty('loading',false);
		
		this.$store.dispatch('fetchUser');
		this.$store.dispatch('fetchGigs');
	


	})
	.catch(error => {
		if (error.response) {

		console.log(error.response.data);

		} else if (error.request) {

		console.log(error.request);
		} else {

		console.log('Error', error.message);
		}
	});



}


},

watch: {

	'user.updated_at': {
		handler() {

			// Ik maak een kopie omdat ik de data naar de api wil sturen en de api de store wil laten updaten.
			
		
				this.localuser = JSON.parse(JSON.stringify(this.user));


				if (this.localuser.settings) {


					this.localuser.settings = JSON.parse(this.localuser.settings);


					// Settings aanmaken als ze niet bestaan

					if (this.localuser.settings === null) {
					this.localuser.settings = {};
					}

					if (!('darktheme' in this.localuser.settings)) {

						this.localuser.settings.darktheme = false; 
					}





				}
		

			
		},
		immediate: true 
		}
	},

}

</script>

<style scoped>

</style>
