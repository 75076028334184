<template>

	<transition name="slide-right">

		<div class="panel" v-if="this.active.panel4=='UserHomeBandsBandInfo'" >

		<div class="debug">UserHomeBandsBandInfo</div>

		<div class="header">
			<a class="left" @click="$setActiveProperty('panel4', '')"><LeftIcon/></a>
			<div class="title center">{{band.name}}</div>

		</div>



		<div class="content">

			<h2>Members</h2>
			<table class="form">


				
				<tr v-for="user in this.band.users" :key="user.id">

					<td>{{user.email }}</td>
					<td>{{user.pivot.role }}</td>

				</tr>

			</table>

	
		</div>





		</div>

	</transition>

</template>


<script>

import LeftIcon from '@/components/common/icons/LeftIcon.vue';

import { mapState } from 'vuex';


export default{

data(){
     return {

     }
},

components: {
	LeftIcon
},
computed:{

	...mapState(['active','translate','band']),




},



methods: {


},

watch: {



},
}
</script>

<style scoped>

</style>
