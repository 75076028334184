<template>

	<transition name="slide-right" v-if="band">

		<div class="panel" v-if="this.active.panel3=='UserHomeBandsBand'" >


			<div class="debug">userhomebandsband</div>

			<div class="header">

				<a class="left" @click="$setActiveProperty('panel3', '')"><LeftIcon/></a>

				<div class="title center">{{band.name}}</div>
			</div>


			<div class="content">

				<a  class="button grey w100" @click="$setActiveProperty('panel4', 'UserHomeBandsBandInfo')">Info <RightIcon/></a>

				<a  class="button grey w100" @click="$setActiveProperty('panel4', 'UserHomeBandsBandSettings')">Settings<RightIcon/></a>
				

				

				<a v-if="band.role=='owner'" class="button grey w100" @click="$setActiveProperty('panel4', 'UserHomeBandsBandAdminsettings')">Admin Setting <span class="badge">admin</span> <RightIcon/></a>
				
				<a v-if="band.role=='owner'" class="button grey w100" @click="$setActiveProperty('panel4', 'UserHomeBandsBandMembers')">Members <span class="badge">admin</span> <RightIcon/></a>

				<a v-if="band.role=='owner'" class="button grey w100" @click="$setActiveProperty('panel4', 'UserHomeBandsBandInfos')">Infos <span class="badge">admin</span> <RightIcon/></a>
				<a v-if="band.role=='owner'" class="button grey w100" @click="$setActiveProperty('panel4', 'UserHomeBandsBandStatuses')">Statuses <span class="badge">admin</span> <RightIcon/></a>
				<a v-if="band.role=='owner'" class="button grey w100" @click="$setActiveProperty('panel4', 'UserHomeBandsBandTypes')">Types <span class="badge">admin</span><RightIcon/> </a>

				<a v-if="band.role=='owner'" class="button grey w100" @click="$setActiveProperty('panel4', 'UserHomeBandsBandChecklist')">Checklist <span class="badge">admin</span> <RightIcon/></a>
				
			</div>








		</div>

	</transition>

</template>


<script>


import LeftIcon from '@/components/common/icons/LeftIcon.vue';
import RightIcon from '@/components/common/icons/RightIcon.vue';


import { mapState } from 'vuex';


export default{

data(){
     return {

     }
},

components: {
	LeftIcon,
	RightIcon

},

methods: {




},

computed:{

	...mapState(['active','band']),




},




watch: {



},
}
</script>

<style scoped>


.swiper-slide {
  font-size: 18px;
  height: calc(100vh - 112px);
  width: 100vw;
  box-sizing: border-box;
  position: relative;
}

</style>
