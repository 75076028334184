<template>


<svg class="list icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 5.75C7.59 5.75 7.25 5.41 7.25 5V2C7.25 1.59 7.59 1.25 8 1.25C8.41 1.25 8.75 1.59 8.75 2V5C8.75 5.41 8.41 5.75 8 5.75Z"  />
<path d="M16 5.75C15.59 5.75 15.25 5.41 15.25 5V2C15.25 1.59 15.59 1.25 16 1.25C16.41 1.25 16.75 1.59 16.75 2V5C16.75 5.41 16.41 5.75 16 5.75Z"  />
<path d="M18.2 22.15C16.02 22.15 14.25 20.38 14.25 18.2C14.25 16.02 16.02 14.25 18.2 14.25C20.38 14.25 22.15 16.02 22.15 18.2C22.15 20.38 20.38 22.15 18.2 22.15ZM18.2 15.75C16.85 15.75 15.75 16.85 15.75 18.2C15.75 19.55 16.85 20.65 18.2 20.65C19.55 20.65 20.65 19.55 20.65 18.2C20.65 16.85 19.55 15.75 18.2 15.75Z"  />
<path d="M21.9995 22.7499C21.8095 22.7499 21.6195 22.6799 21.4695 22.5299L20.4695 21.5299C20.1795 21.2399 20.1795 20.7599 20.4695 20.4699C20.7595 20.1799 21.2395 20.1799 21.5295 20.4699L22.5295 21.4699C22.8195 21.7599 22.8195 22.2399 22.5295 22.5299C22.3795 22.6799 22.1895 22.7499 21.9995 22.7499Z"  />
<path d="M8.5 14.4999C8.37 14.4999 8.24 14.4699 8.12 14.4199C7.99 14.3699 7.87999 14.2999 7.78999 14.2099C7.74999 14.1599 7.7 14.1099 7.67 14.0599C7.63 13.9999 7.6 13.9399 7.58 13.8799C7.55 13.8199 7.53 13.7599 7.52 13.6999C7.51 13.6299 7.5 13.5599 7.5 13.4999C7.5 13.2399 7.60999 12.9799 7.78999 12.7899C7.87999 12.6999 7.99 12.6299 8.12 12.5799C8.48 12.4199 8.93001 12.5099 9.21001 12.7899C9.39001 12.9799 9.5 13.2399 9.5 13.4999C9.5 13.5599 9.49 13.6299 9.48 13.6999C9.47 13.7599 9.45 13.8199 9.42 13.8799C9.4 13.9399 9.37 13.9999 9.33 14.0599C9.29 14.1099 9.25001 14.1599 9.21001 14.2099C9.02001 14.3899 8.76 14.4999 8.5 14.4999Z"  />
<path d="M12 14.4999C11.74 14.4999 11.48 14.3899 11.29 14.2099C11.11 14.0199 11 13.7699 11 13.4999C11 13.3699 11.03 13.2399 11.08 13.1199C11.13 12.9999 11.2 12.89 11.29 12.79C11.66 12.42 12.33 12.42 12.71 12.79C12.89 12.98 13 13.2399 13 13.4999C13 13.5599 12.99 13.63 12.98 13.7C12.97 13.76 12.95 13.8199 12.92 13.8799C12.9 13.9399 12.87 13.9999 12.83 14.0599C12.79 14.1099 12.75 14.1599 12.71 14.2099C12.52 14.3899 12.26 14.4999 12 14.4999Z"  />
<path d="M8.5 17.9999C8.24 17.9999 7.97999 17.8899 7.78999 17.7099C7.60999 17.5199 7.5 17.2599 7.5 16.9999C7.5 16.8699 7.53 16.7399 7.58 16.6199C7.63 16.4899 7.69999 16.38 7.78999 16.29C8.15999 15.92 8.84001 15.92 9.21001 16.29C9.39001 16.48 9.5 16.7399 9.5 16.9999C9.5 17.2599 9.39001 17.5199 9.21001 17.7099C9.02001 17.8899 8.76 17.9999 8.5 17.9999Z"  />
<path d="M20.5 9.83984H3.5C3.09 9.83984 2.75 9.49984 2.75 9.08984C2.75 8.67984 3.09 8.33984 3.5 8.33984H20.5C20.91 8.33984 21.25 8.67984 21.25 9.08984C21.25 9.49984 20.91 9.83984 20.5 9.83984Z"  />
<path d="M13.37 22.75H8C4.35 22.75 2.25 20.65 2.25 17V8.5C2.25 4.85 4.35 2.75 8 2.75H16C19.65 2.75 21.75 4.85 21.75 8.5V13C21.75 13.41 21.41 13.75 21 13.75C20.59 13.75 20.25 13.41 20.25 13V8.5C20.25 5.64 18.86 4.25 16 4.25H8C5.14 4.25 3.75 5.64 3.75 8.5V17C3.75 19.86 5.14 21.25 8 21.25H13.37C13.78 21.25 14.12 21.59 14.12 22C14.12 22.41 13.78 22.75 13.37 22.75Z"  />
</svg>

</template>