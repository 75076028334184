<template>
<swiper :onSwiper="setScreensSwiper" @slideChange="updateScreens" slidesPerView="1" spaceBetween="50" class="agenda-swiper" :initialSlide="2">
     <swiper-slide>
          <div class="years">
               <swiper ref="monthsSwiper" :direction="'vertical'" slidesPerView="1" @slideChange="updateYears" :initialSlide="yearsInitial">
                    <swiper-slide class="screens" v-for="year in years" :key="year">
                         jaar {{year}}
                    </swiper-slide>
               </swiper>
          </div>
     </swiper-slide>
     <swiper-slide>
          <div class="months">
               <swiper ref="monthsSwiper" :onSwiper="setMonthsSwiper" :direction="'vertical'" slidesPerView="auto" @slideChange="updateMonths" :initialSlide="monthsInitial">
                    <swiper-slide class="screens" v-for="month in months" :key="month">
                         <CalenderMonth :monthNumber="parseInt(month,10)"  />
                    </swiper-slide>
               </swiper>
          </div>
     </swiper-slide>
     <swiper-slide>
          <div class="weeks">
               <swiper ref="weeksSwiper" :onSwiper="setWeeksSwiper" :direction="'vertical'" slidesPerView="auto" @slideChange="updateWeeks" :initialSlide="weeksInitial">
                    <swiper-slide class="screens" v-for="week in weeks" :key="week">
                         <CalenderWeek :weekNumber="parseInt(week,10)" />
                    </swiper-slide>
               </swiper>
          </div>
     </swiper-slide>
     <swiper-slide>
          <div class="days">
               <swiper ref="daysSwiper" :onSwiper="setDaysSwiper" :direction="'vertical'" slidesPerView="auto" @slideChange="updateDays" :initialSlide="daysInitial">
                    <swiper-slide class="screens" v-for="day in days" :key="day">
                         <CalenderDay :dayNumber="parseInt(day,10)" />
                    </swiper-slide>
               </swiper>
          </div>
     </swiper-slide>


	<swiper-slide class="screens">
     
		<CalenderList />
            
	</swiper-slide>

	<swiper-slide class="screens">
     
		<CalenderRequests />
     
	</swiper-slide>

</swiper>






<GigsHeader />

<GigsFooter />

<a class="addgig" @click="$setActiveProperty('panel2', 'GigHomeCreate')"><NewIcon/></a>

<Overlay0/>

<div class="panel1">
	<UserHome/>
	<GigHome />
     <AppointmentHome />
     <FilterHome/>
     <GigsSetname/>
</div>

<Overlay1/>


<div class="panel2">
	<UserHomeAccount />
	<UserHomeBands/>
     <GigHomeCreate/>
     <AppointmentHomeDelete/>
     <GigHomeAnswerYes/>
     <GigHomeAnswerNo/>
     <GigHomeAnswerMaybe/>
</div>


<Overlay2/>

<div class="panel3">
	<UserHomeBandsCreate />
	<UserHomeBandsBand/>
     <GigHomeCreateBands/>
     <GigHomeCreateTypes/>
     <GigHomeCreateStatuses/>

</div>

<Overlay3/>

<div class="panel4">
     <UserHomeBandsBandInfo/>
     <UserHomeBandsBandSettings/>
	<UserHomeBandsBandInfos/>
	<UserHomeBandsBandStatuses/>
	<UserHomeBandsBandTypes/>
     <UserHomeBandsBandMembers/>
     <UserHomeBandsBandAdminsettings/>
     <UserHomeBandsBandChecklist/>

</div>

<Overlay4/>

<div class="panel5">
	<UserHomeBandsBandStatusesCreate/>
	<UserHomeBandsBandStatusesDelete/>
	<UserHomeBandsBandTypesCreate/>
	<UserHomeBandsBandTypesDelete/>
     <UserHomeBandsBandAdminsettingsColor/>
</div>




<GigsLoading/>




</template>

<script>
import CalenderMonth from '@/components/calender/CalenderMonth.vue';
import CalenderWeek from '@/components/calender/CalenderWeek.vue';
import CalenderDay from '@/components/calender/CalenderDay.vue';
import CalenderList from '@/components/calender/CalenderList.vue';
import CalenderRequests from '@/components/calender/CalenderRequests.vue';
import FilterHome from '@/components/calender/FilterHome.vue'

import GigsFooter from '@/components/common/GigsFooter.vue';
import GigsHeader from '@/components/common/GigsHeader.vue';
import GigsLoading from '@/components/common/GigsLoading.vue';
import GigsSetname from '@/components/common/panels/GigsSetname.vue';



// Gig

import GigHome from '@/components/gig/GigHome.vue';
import GigHomeCreate from '@/components/gig/GigHomeCreate.vue';
import GigHomeCreateBands from '@/components/gig/GigHomeCreateBands.vue';
import GigHomeCreateTypes from '@/components/gig/GigHomeCreateTypes.vue';
import GigHomeCreateStatuses from '@/components/gig/GigHomeCreateStatuses.vue';
import AppointmentHome from '@/components/gig/AppointmentHome.vue';
import AppointmentHomeDelete from '@/components/gig/AppointmentHomeDelete.vue';



import GigHomeAnswerYes from '@/components/gig/GigHomeAnswerYes.vue';
import GigHomeAnswerNo from '@/components/gig/GigHomeAnswerNo.vue';
import GigHomeAnswerMaybe from '@/components/gig/GigHomeAnswerMaybe.vue';

// Users

import UserHome from '@/components/user/panels/UserHome.vue';
import UserHomeAccount from '@/components/user/panels/UserHomeAccount.vue';
import UserHomeBands from '@/components/user/panels/UserHomeBands.vue';
import UserHomeBandsCreate from '@/components/user/panels/UserHomeBandsCreate.vue';
import UserHomeBandsBand from '@/components/user/panels/UserHomeBandsBand.vue';
import UserHomeBandsBandStatuses from '@/components/user/panels/UserHomeBandsBandStatuses.vue';
import UserHomeBandsBandStatusesCreate from '@/components/user/panels/UserHomeBandsBandStatusesCreate.vue';
import UserHomeBandsBandStatusesDelete from '@/components/user/panels/UserHomeBandsBandStatusesDelete.vue';
import UserHomeBandsBandTypes from '@/components/user/panels/UserHomeBandsBandTypes.vue';
import UserHomeBandsBandTypesCreate from '@/components/user/panels/UserHomeBandsBandTypesCreate.vue'
import UserHomeBandsBandTypesDelete from '@/components/user/panels/UserHomeBandsBandTypesDelete.vue'
import UserHomeBandsBandInfos from '@/components/user/panels/UserHomeBandsBandInfos.vue'
import UserHomeBandsBandSettings from '@/components/user/panels/UserHomeBandsBandSettings.vue'
import UserHomeBandsBandInfo from '@/components/user/panels/UserHomeBandsBandInfo.vue'
import UserHomeBandsBandMembers from '@/components/user/panels/UserHomeBandsBandMembers.vue'
import UserHomeBandsBandAdminsettings from '@/components/user/panels/UserHomeBandsBandAdminsettings.vue'
import UserHomeBandsBandAdminsettingsColor from '@/components/user/panels/UserHomeBandsBandAdminsettingsColor.vue'
import UserHomeBandsBandChecklist from '@/components/user/panels/UserHomeBandsBandChecklist.vue'




import NewIcon from '@/components/common/icons/NewIcon.vue';

import Overlay0 from '@/components/common/OverLay0.vue'
import Overlay1 from '@/components/common/OverLay1.vue'
import Overlay2 from '@/components/common/OverLay2.vue'
import Overlay3 from '@/components/common/OverLay3.vue'
import Overlay4 from '@/components/common/OverLay4.vue'


import { Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import { mapState } from 'vuex';
export default {
components: {
	Swiper,
	SwiperSlide,
     Overlay0,
     Overlay1,
     Overlay2,
     Overlay3,
     Overlay4,
	CalenderMonth,
	CalenderWeek,
	CalenderDay,
	CalenderList,
	CalenderRequests,
     FilterHome,
	GigsFooter,
     GigsHeader,
     GigsLoading,
     GigsSetname,
	UserHome,
	GigHome,
     GigHomeAnswerYes,
     GigHomeAnswerNo,
     GigHomeAnswerMaybe,
	UserHomeAccount,
	UserHomeBands,
	UserHomeBandsCreate,
	UserHomeBandsBand,
     UserHomeBandsBandInfo,
     UserHomeBandsBandMembers,
	UserHomeBandsBandStatuses,
	UserHomeBandsBandStatusesCreate,
	UserHomeBandsBandStatusesDelete,
	UserHomeBandsBandTypes,
     UserHomeBandsBandAdminsettings,
     UserHomeBandsBandAdminsettingsColor,
	UserHomeBandsBandTypesCreate,
	UserHomeBandsBandTypesDelete,
	UserHomeBandsBandInfos,
     UserHomeBandsBandSettings,
     UserHomeBandsBandChecklist,
     GigHomeCreate,
     GigHomeCreateBands,
     GigHomeCreateTypes,
     GigHomeCreateStatuses,
     AppointmentHome,
     AppointmentHomeDelete,
     NewIcon


  },
  data() {
    return {
    };
  },
  methods:{


     checkname(){

          if (this.user.name){

      
               this.$setActiveProperty('panel1', '');

               

          } else {
              
               this.$setActiveProperty('panel1', 'GigsSetname');
          }

     },

  

	updateScreens(swiper){

		const activeIndex = swiper.activeIndex;
		this.$setActiveProperty('CalenderScreen', activeIndex);

	},

     updateYears(swiper){
          const activeIndex = swiper.activeIndex;
          var year = 2019 + activeIndex;
          this.$setActiveProperty('currentYear', year);
     },
     updateMonths(swiper) {
          const activeIndex = swiper.activeIndex;
          var month = activeIndex + 1;
          this.$setActiveProperty('currentMonth', month);
     },
     updateWeeks(swiper) {
          const activeIndex = swiper.activeIndex;
          var week = activeIndex + 1;
          this.$setActiveProperty('currentWeek', week);
     },
     updateDays(swiper) {
          const activeIndex = swiper.activeIndex;
          var day = activeIndex + 1;
          this.$setActiveProperty('currentDay', day);
     },
	setScreensSwiper(swiper) {
	this.screensswiper = swiper;

	},
     setMonthsSwiper(swiper) {
      this.monthsswiper = swiper;
     },
     setWeeksSwiper(swiper) {
      this.weeksswiper = swiper;
     },
     setDaysSwiper(swiper) {
      this.daysswiper = swiper;
     },
},
computed:{
     ...mapState(['active','user']),



 
     years(){
          let years = [];
          for (let year = 2019; year <= 2050; year++) {
            years.push(year);
          }
          return years;
     },
     yearsInitial(){
          var initial = this.active.currentYear - 2019;
          return initial;
     },
     months(){
          let months = [];
          for (let month = 1; month <= 12; month++) {
            months.push(month);
          }
          return months;
     },
     monthsInitial(){
          var initial = this.active.currentMonth -1;
          return initial;
     },
     weeks(){
          // Aantal weken in dit jaar berekenen;
          const year = this.active.currentYear;
          const start = new Date(year, 0, 1);
          const end = new Date(year, 11, 31);
          const days = (end - start) / (1000 * 60 * 60 * 24) + 1;
          const startDay = start.getDay();
          const endDay = end.getDay();
          let numberOfWeeks = Math.floor(days / 7);
          if ((startDay === 0 && endDay !== 6) || (startDay !== 1 && endDay === 6)) {
            numberOfWeeks += 1;
          }
          let weeks = [];
          for (let week = 1; week <= numberOfWeeks; week++) {
            weeks.push(week);
          }
          return weeks;
     },
     weeksInitial(){
          var initial = this.active.currentWeek -1;
          return initial;
     },
     days(){
          const year = this.active.currentYear;
          const month = this.active.currentMonth;
          var numberOfDays = new Date(year, month, 0).getDate();
          let weeks = [];
          for (let week = 1; week <= numberOfDays; week++) {
            weeks.push(week);
          }
          return weeks;
     },
     daysInitial(){
          var initial = this.active.currentDay -1;
          return initial;
     },
	gotoScreenChanges(){
		return this.active.gotoCalenderScreen;
	},
     gotoMonthChanges(){
          return this.active.gotoMonth;
     },
     gotoWeekChanges(){
          return this.active.gotoWeek;
     },
     gotoDayChanges(){
          return this.active.gotoDay;
     },
     userChanges(){

          return this.user;

     }
 
},
watch: {

	gotoScreenChanges(){

		var screen = this.active.gotoCalenderScreen;

		this.screensswiper.slideTo(screen);


	},
     // Wanneer  de weken naar een andere maand gaan, de maand bijwerken
     gotoMonthChanges(){
          var slideIndex = this.active.gotoMonth - 1;
          this.monthsswiper.slideTo(slideIndex,0);
     },
     gotoWeekChanges(){
          var slideIndex = this.active.gotoWeek - 1;
          this.weeksswiper.slideTo(slideIndex,0);
     },
     gotoDayChanges(){
          var slideIndex = this.active.gotoDay - 1;
          this.daysswiper.slideTo(slideIndex,0);
     },
     userChanges(){

          this.checkname();

     }
  
},
created(){


     this.checkname();



}

};
</script>
<style>



.swiper {
height: 100dvh;
overflow: hidden;
box-sizing: border-box;
}

.years {
     display: flex;
     justify-content: center;
     align-items: center;
}
.years, .months, .weeks, .days {
  height: 100%;
}
.swiper-slide { 
box-sizing: border-box;
height: 100dvh;
}

.swiper-slide.screens .screen{
     padding-top: 48px; /* ruimte voor de header */
     padding-bottom: 64px; /* ruimte voor de footer */
     padding-bottom: 72px;
     padding-top: 64px;
}

.addgig{

position: fixed;
right: 24px;
bottom: 80px;
z-index: 10;

}

.addgig .icon{
width: 56px;
height: 56px;
}

.addgig .icon path{

fill: var(--blauw);
}
</style>
