import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import Pusher from 'pusher-js';

import './assets/normalize.css';
import './assets/global.css';


const app = createApp(App);

// Initialiseer Pusher
const pusher = new Pusher('1496a9a186a4f6e7d9a1', {
	cluster: 'eu',
   });
   
   // Voeg Pusher toe aan de globale eigenschappen van je Vue-applicatie
   app.config.globalProperties.$pusher = pusher;



app.config.globalProperties.$setActiveProperty = function(property, value) {
  store.commit('setActiveProperty', { property, value });
};


app.config.globalProperties.$translate = function(string) {

	const language = store.state.active.language;

	let translation;

	try {
	translation = store.state.translate[string][language];
	} catch (error) {
	translation = "TRANSLATION NOT FOUND";
	}

	return translation;

};





app.use(router).use(store).mount('#app');
