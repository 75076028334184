<template>

	<transition name="slide-right" v-if="band">

		<div class="panel" v-if="this.active.panel4=='UserHomeBandsBandTypes'" >


			<div class="debug">UserHomeBandsBandTypes</div>

			<div class="header">

				<a class="left" @click="$setActiveProperty('panel4', '')"><LeftIcon/></a>

				<div class="title center">{{$translate('UserHomeBandsBandTypes_Title')}} {{band.name}}</div>
			</div>


			<div class="content">

			
				


				<div class="mb16">{{$translate('UserHomeBandsBandTypes_Text')}}</div>



				<div v-for="type in band.types" :key="type.id" class="status-container">

					<div class="button grey w100	mr8 mb8" >
						{{type.name}}
					</div>

					<span @click="statusClickHandler(type)"><DeleteIcon/></span>


				</div>


				<div class="button fr addbutton" @click="$setActiveProperty('panel5', 'UserHomeBandsBandTypesCreate')">
					Create Type
				</div>

			</div>


		</div>

	</transition>



</template>


<script>
import { mapState } from 'vuex';
import LeftIcon from '@/components/common/icons/LeftIcon.vue';
import DeleteIcon from '@/components/common/icons/DeleteIcon.vue';

export default{

data(){
     return {

     }
},

components: {
	LeftIcon,
	DeleteIcon
},
computed:{

	...mapState(['active','translate','band']),




},



methods: {


	statusClickHandler(type){


		this.$setActiveProperty('type', type);
		this.$setActiveProperty('panel5', 'UserHomeBandsBandTypesDelete');



	}


},

watch: {



},
}
</script>

<style scoped>

.status-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.button.ghost {
    flex-grow: 1;
    margin-right: 8px;
}

</style>
