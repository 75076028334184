<template>





     <div class="overlay twee"  v-if="show" :class="{ 'open': show }" @click="$setActiveProperty('panel3', '')" >


          

     </div>




</template>

<script>

import { mapState } from 'vuex';

export default{

data(){
     return {

     }
},

components: {

},
computed:{

	...mapState(['active']),

     show(){

          var panel = this.active.panel3;

          if (panel){
               return true;
          } else {

               return false;
          }

          
     }




},



methods: {


},

watch: {



},
}
</script>

<style scoped>

</style>
