<template>
	<transition name="slide-right">
		<div class="panel" v-if="this.active.panel4 == 'UserHomeBandsBandChecklist'">
			<div class="debug">UserHomeBandsBandChecklist</div>
			<div class="header">
				<a class="left" @click="$setActiveProperty('panel4', '')"><LeftIcon/></a>
				<div class="title center">{{band.name}}</div>
			</div>

			<div class="content">
			
                    <div class="title">{{$translate('UserHomeBandsBandChecklist_Title')}}</div>
				<div class="text">{{$translate('UserHomeBandsBandChecklist_Text')}}</div>

				<draggable v-model="localChecklists" class="" item-key="checklist.id" @end="updateItems">
					<template #item="{element: checklist}">

						<div class="container">
							<div class="icon order links"><OrderIcon/></div>

							<a class="button grey w100 midden mr16"> {{ checklist.name }} </a>
							<div class="icon delete rechts" @click="deleteField(checklist.id)"><DeleteIcon/></div>
						</div>

					</template>
				</draggable>


				<div class="addbutton" placeholder="">

					<input v-model="fieldname" type="text" placeholder="Fieldname" class="mr15" />

					<div class="button fr" @click="newfield()">
						Add field
					</div>

				</div>



			</div>
		</div>
	</transition>
</template>

<script>
import { mapState } from 'vuex';
import draggable from 'vuedraggable';
import axios from 'axios';

import LeftIcon from '@/components/common/icons/LeftIcon.vue';
import DeleteIcon from '@/components/common/icons/DeleteIcon.vue';
import OrderIcon from '@/components/common/icons/OrderIcon.vue';

export default {
components: {
	draggable,
	LeftIcon,
     DeleteIcon,
     OrderIcon
},
data() {
	return {
		localChecklists: ['temp'], // Lokale kopie van checklists
		fieldname: '',
	}
},
computed: {
	...mapState(['active', 'statuses', 'translate','config','band']),

	checklists(){

		try {

			return JSON.parse(this.band.checklists);

		} catch (error) {

			return [];
		}

	}
},
methods: {


	deleteField(id){


		this.localChecklists = this.localChecklists.filter(element => element.id !== id);
		this.updateItems();

	},


	newfield(){

		var field = {};
		field.name = this.fieldname;
		field.id = 1;
		field.value = false;

		this.localChecklists.push(field);

		this.updateItems();

		this.fieldname = '';

	},
	updateItems() {

     
          var checklists = this.localChecklists.map((item, index) => ({
          ...item,
          id: index + 1
          }));





		var post = {};

		post.task = 'updateChecklists';
		post.usertoken = this.active.usertoken;
		post.bandId = this.active.bandId;
		post.checklists = checklists;


     


		axios.post(this.config.apiserver + 'bands', post )

		.then(response => {

			if (this.config.debug==1){
				console.log(response.data);

			}
			this.$store.dispatch('fetchBands');



		})
		.catch(error => {
		console.error(error);



		});

	},
},
watch: {

	checklists: {
		handler(newChecklists) {

		if (newChecklists){

			this.localChecklists = [...newChecklists];
		} else {

			this.localChecklists = [];
		}


		},
		immediate: true // Dit zorgt ervoor dat de watcher direct bij het laden wordt uitgevoerd
	}

},

}
</script>

<style scoped>
.container {
display: flex;
align-items: center; /* Voegt verticale centrering toe */
}

.links, .rechts {
  flex: 0 0 auto;
}

.icon.order {
  width: 24px;
  height: 24px;
  stroke: #fff;
  position: relative;
  top: -4px;
  margin-right: 8px;
}

.icon.delete{
width: 24px;
  height: 24px;
  position: relative;
  top: -4px;

}

.midden {
  flex: 1;
}



</style>
