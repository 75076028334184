<template>

	<transition name="slide-up">

		<div class="panel autoheight" v-if="this.active.panel5=='UserHomeBandsBandTypesDelete'" >
			<div class="debug">userhomebandsbandTypesDelete</div>
			<div class="header">

				<a class="right" @click="$setActiveProperty('panel5', '')"><DownIcon/></a>
			</div>

			<div class="content centertext">

				{{active.type.name}} verwijderen? <br><br>
				<a class="button" @click="submit">Verwijderen</a>
			</div>

		</div>

	</transition>

</template>


<script>

import axios from 'axios';
import DownIcon from '@/components/common/icons/DownIcon.vue';

import { mapState } from 'vuex';



export default{

data(){
     return {

     }
},

components: {
	DownIcon
},

methods: {


	async submit() {

		var post = {};

		post.task = 'deleteType';
		post.usertoken = this.active.usertoken;
		post.typeId = this.active.type.id;

		// console.log(post);

		axios.post(this.config.apiserver + 'types', post )

		.then(() => {

			// console.log(response.data);

			this.$store.dispatch('fetchBands');
			this.$setActiveProperty('panel5','');


		})
		.catch(error => {
		console.error(error);



		});
	},






},

computed:{

	...mapState(['active','config']),




},




watch: {



},
}
</script>

<style scoped>



</style>
