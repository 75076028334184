<template>

	<transition name="slide-up">

		<div class="panel bgblauw" v-if="this.active.panel1=='GigsSetname'" >

		<div class="debug">GigsSetname</div>

		<div class="content centercenter">



			<div class="centertext colorwhite">

				We willen 1 ding van je weten,<br>
				hoe heet je?


				
				<input v-model="name" type="text" class="colorblack tac">
				
				<a class="button bgwhite colorblauw" @click="submit">Verstuur</a>

			</div>


			
		
			
		
		</div>






		</div>

	</transition>

</template>


<script>

import { mapState } from 'vuex';
import axios from 'axios';




export default{

data(){
     return {
		name: ''
     }
},

components: {

},
computed:{

	...mapState(['active','translate','user','config']),




},



methods: {

	async submit() {

		var post = {};

		post.task = 'updateUser';
		post.usertoken = this.active.usertoken;
		post.name = this.name;



		axios.post(this.config.apiserver + 'users', post )

		.then(response => {

			console.log(response.data);


			this.$store.dispatch('fetchUser');
		


		})
		.catch(error => {
		console.error(error);



		});
		
	},


},

watch: {



},
}
</script>

<style scoped>




</style>
