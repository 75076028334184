<template>

     <transition name="fade">

          <div class="overlay een" v-if="show" :class="{ 'open': show }" @click="$setActiveProperty('panel2', '')">

          
               

          </div>

     </transition>



</template>

<script>

import { mapState } from 'vuex';

export default{

data(){
     return {

     }
},

components: {

},
computed:{

	...mapState(['active']),

     show(){

     var panel = this.active.panel2;

     if (panel){
          return true;
     } else {

          return false;
     }


     }





},



methods: {


},

watch: {



},
}
</script>

<style scoped>

</style>
