<template>



		<div class="screen centercenter" >

	
                    <div class="centertext">


                         Kan je spelen? <br><br>

                         <a class="button"   @click="this.$setActiveProperty('panel2','AnswerHomeYes');">Ja</a><br>
                         <a class="button"   @click="this.$setActiveProperty('panel2','AnswerHomeNo');">Nee</a><br>
                         <a class="button"   @click="this.$setActiveProperty('panel2','AnswerHomeMaybe');">Misschien</a><br>
                    
                      


                    </div>

                       
                                         
		


		</div>



</template>


<script>


import { mapState } from 'vuex';


export default{

data(){
     return {

          

     }
},

components: {




},
computed:{

	...mapState(['active','gig','translate']),



},



methods: {

    


	


},


}
</script>

<style scoped>



.screen{
	height: calc(100dvh - 210px) ;


}

</style>

