<template>

	<transition name="slide-right">

		<div class="panel" v-if="this.active.panel2=='UserHomeBands'" >

			<div class="debug">UserHomeBands</div>

			<div class="header">
				<a class="left" @click="$setActiveProperty('panel2', '')"><LeftIcon/></a>
				<div class="title center">Your Bands</div>

			</div>

			<div class="content">


				<div v-for="band in bands" :key="band.id">

					<div class="button grey w100" @click="handleBandClick(band.id)">
						{{band.name}} 
						
						<span class="badge" v-if="band.role=='owner'">admin</span>


						<RightIcon/>
					</div>

				</div>


			</div>


			<div class="button bottomright" @click="$setActiveProperty('panel3', 'UserHomeBandsCreate')">
				New Band
			</div>



		</div>

	</transition>

</template>


<script>

import { mapState } from 'vuex';
import LeftIcon from '@/components/common/icons/LeftIcon.vue';
import RightIcon from '@/components/common/icons/RightIcon.vue';


export default{

data(){
     return {

     }
},

components: {
	LeftIcon,
	RightIcon
},

methods: {

	handleBandClick(bandId){

		this.$setActiveProperty('bandId', bandId);
		this.$setActiveProperty('panel3', 'UserHomeBandsBand');
	},

	closepanels(){
		this.$setActiveProperty('panel1', '')
		this.$setActiveProperty('panel2', '')
	},
	


},

computed:{

	...mapState(['active','bands','translate']),




},




watch: {



},
}
</script>

<style scoped>

</style>
