<template>
  <div class="screen">
      <h2>{{ monthNumber }} | {{ year }}</h2>
          <div class="row heading">
            <div class="col">ma</div>
            <div class="col">di</div>
            <div class="col">wo</div>
            <div class="col">do</div>
            <div class="col">vr</div>
            <div class="col">za</div>
            <div class="col">zo</div>
          </div>

          <div class="week" v-for="(week, index) in weeks" :key="index" @mouseover="handleWeekClick(week.weekNumber)" @touchstart="handleWeekClick(week.weekNumber)">

              <div class="day-numbers">
                <span v-for="day in week.days" :key="day.day" :class="{ 'not-current-month': !day.isCurrentMonth }">
                {{ day.day }}
                </span>
              </div>


              <div class="gig-container" v-for="gig in week.gigs" :key="gig.id" 
              :style="{ width: calculateWidth(gig.start, gig.end, week.weekStartDate, week.weekEndDate) + '%', 
              left: calculateOffset(gig.start, week.weekStartDate) + '%' }"
              >


              <span v-if="gig.sort=='appointment'">

                  <span v-for="band in gig.ownersbands" :key="band.id" :class="['bullit', band.color]">
                            {{ band.name[0] }}
                  </span>

                  <span class="bold small" v-if="gig.user.name">{{gig.user.name}}&nbsp;</span>
                  <span class="bold small" v-if="!gig.user.name">{{gig.user.email}}&nbsp;</span>
                  <span class=" small">{{ gig.venue }}&nbsp;</span>


              </span>

              <span v-if="gig.sort=='band'">

  

                <span class="bold small" >{{gig.band.name}}&nbsp;</span>
    
                <span class="small">{{ gig.venue }}&nbsp;</span>


              </span>


               








          </div>

      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    monthNumber: Number,
  },
  computed: {
    ...mapState(['active', 'gigsbyweek']),
    year() {
      return '2024';  // Pas het jaar aan indien nodig
    },
    weeks() {
      const weeks = [];
      const monthStart = new Date(this.year, this.monthNumber - 1, 1);
      let currentDate = new Date(monthStart);
      currentDate.setDate(currentDate.getDate() - (currentDate.getDay() === 0 ? 6 : currentDate.getDay() - 1));

      while (currentDate.getDay() !== 1 || weeks.length === 0 || currentDate.getMonth() === monthStart.getMonth()) {
        const weekStartDate = new Date(currentDate);
        let week = [];
        for (let i = 0; i < 7; i++) {
          week.push({
            day: currentDate.getDate(),
            isCurrentMonth: currentDate.getMonth() === monthStart.getMonth()
          });
          currentDate.setDate(currentDate.getDate() + 1);
        }
        const weekEndDate = new Date(currentDate);
        weekEndDate.setDate(weekEndDate.getDate() - 1);
        const weekKey = `${this.year}-W${this.getWeekNumber(weekStartDate)}`;

        const weekNumber = this.getWeekNumber(weekStartDate);


        const gigs = (this.gigsbyweek[weekKey] || []).sort((a, b) => a.id - b.id);


        weeks.push({
          weekStartDate,
          weekEndDate,
          weekKey,
          weekNumber,
          days: week,
          gigs: gigs,
        });
      }
      return weeks;
    }
  },
  methods: {
    getWeekNumber(date) {
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
      const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
      return Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
    },
    calculateOffset(startDate, weekStartDate) {
    const start = new Date(startDate);
    const weekStart = new Date(weekStartDate);
    if (start < weekStart) {
    return 0; // Start voor de week
    }
    let dayOffset = start.getDay() - 1; // Dag index aanpassen omdat maandag 0 moet zijn.
    if (dayOffset === -1) { // Zondag correctie
    dayOffset = 6;
    }
    return dayOffset * 14.28; // Percentage voor een dag
    },
    calculateWidth(startDate, endDate, weekStartDate, weekEndDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const weekStart = new Date(weekStartDate);
    const weekEnd = new Date(weekEndDate);
    weekEnd.setHours(23, 59, 59, 999); // Zet het einde van de week naar het einde van zondag

    const effectiveStart = start < weekStart ? weekStart : start;
    const effectiveEnd = end > weekEnd ? weekEnd : end;

    const startDay = effectiveStart.getDay() === 0 ? 6 : effectiveStart.getDay() - 1;
    const endDay = effectiveEnd.getDay() === 0 ? 6 : effectiveEnd.getDay() - 1;
    const durationDays = endDay - startDay;

    return (durationDays + 1) * 14.28; // De breedte aanpassen
    },
    getcolor(gig){
      return gig.id % 10;
    },
    handleWeekClick(weekNumber){


      this.$setActiveProperty('gotoWeek',weekNumber);


    }

  }
}
</script>




<style scoped>


.row{
width: 100%;
clear: both;  
overflow: hidden;
}


.col {
  float: left;
  width: 14.2%;
}  

.calendar {
display: flex;
flex-direction: column;
width: 100%;

}

.week {
display: flex;
flex-direction: column;
overflow: hidden;
height: calc( 100% / 5);

}


.day-numbers {
display: flex;
justify-content: space-between;
padding: 2px;
text-align-last: left;
font-weight: 700;
}

.day-numbers span {
flex: 1;
text-align: center;
}

.gig-container {
position: relative;
padding: 0px 0px;
margin-bottom: 1px;
white-space: nowrap;
line-height: 14px;
color: var(--grey-5);
background: var(--grey-2);
overflow: hidden;
}

.bullit{

background: var(--blauw);
color: var(--white);
font-weight: 700;
font-size: 9px;
line-height: 10px;
height: 12px;
width: 12px;
padding-top: 1px;
padding-left: 0px;
border-radius: 8px;
text-align: center;
display: inline-block;
box-sizing: border-box;
position: relative;
top: -1px;
margin-right: 2px;



}



.not-current-month {
  color: gray;
}

</style>
