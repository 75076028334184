<template>

     <div class="screen">

		<div class="gigs" v-for="gig in gigs" :key="gig.id">


               <div class="gig band" v-if="gig.sort=='band' && isTodayOrLater(gig.start)" @click="handleGigClick(gig)">


                    <div v-if="gig.numberofdays==1">
                         
                         <span class="uppercase small">{{ gig.dateStart.date }} {{ gig.dateStart.monthNameLong }} {{ gig.dateStart.time }}-{{ gig.dateEnd.time }}</span> <span class=" small fr">{{ updated(gig) }}</span></div>

                    <div>   

                    <div v-if="gig.numberofdays>1">

                         <span class="uppercase small">{{ gig.dateStart.date }} {{ gig.dateStart.monthNameLong }} {{ gig.dateStart.time }} - {{ gig.dateEnd.date }} {{ gig.dateEnd.monthNameLong }}  {{ gig.dateEnd.time }}</span> <span class=" small fr"> lastupdate {{ updated(gig) }}</span></div>

                    </div>     
                         

                         <div >

                              <span class="bold">{{gig.band.name}} <span v-if="gig.userstatus=='admin'" class="badge">admin</span></span>
                         
                              <span class="fr small">{{ gig.musicianstatus.invited }}: {{ gig.musicianstatus.yes }}+ {{ gig.musicianstatus.no }}-</span> 
                         </div>
                         <div class="">{{gig.venue}} {{gig.city}}</div>




                    <div class=" small">   {{gig.type.name}} <span class="fr">{{gig.status.name}} </span></div>
               
                   


               </div>


           </div>    


     </div>

</template>


<script>

import { mapState } from 'vuex';

export default{

     data(){
          return {

          }
     },

     components: {

     },
     computed:{

          ...mapState(['active','gigs','translate']),




     },



     methods: {

          handleAnswerClick(gig){



               this.$setActiveProperty('gigId', gig.id);
               this.$setActiveProperty('panel1', 'AnswerHome');


          },

          handleGigClick(gig){

               this.$setActiveProperty('gigId', gig.id);
               this.$setActiveProperty('panel1', 'GigHome');
              

          },

          isTodayOrLater(gigDate) {
               const today = new Date();
               today.setHours(0, 0, 0, 0);
               const gigDateTime = new Date(gigDate);
               return gigDateTime >= today;
          },


          updated(gig){

               var updated = gig.updated_at;

               const now = new Date();
               const pastDate = new Date(updated);
               const diffInSeconds = (now - pastDate) / 1000;

               const formatter = new Intl.RelativeTimeFormat('nl', { numeric: 'auto' });

               // Bereken het verschil in verschillende eenheden
               const secondsPerMinute = 60;
               const secondsPerHour = secondsPerMinute * 60;
               const secondsPerDay = secondsPerHour * 24;
               const secondsPerWeek = secondsPerDay * 7;
               const secondsPerMonth = secondsPerDay * 30;
               const secondsPerYear = secondsPerDay * 365;

               // Bepaal en formatteer de tijdeenheid naar behoefte
               if (diffInSeconds < secondsPerMinute) {
               return formatter.format(-Math.floor(diffInSeconds), 'second');
               } else if (diffInSeconds < secondsPerHour) {
               return formatter.format(-Math.floor(diffInSeconds / secondsPerMinute), 'minute');
               } else if (diffInSeconds < secondsPerDay) {
               return formatter.format(-Math.floor(diffInSeconds / secondsPerHour), 'hour');
               } else if (diffInSeconds < secondsPerWeek) {
               return formatter.format(-Math.floor(diffInSeconds / secondsPerDay), 'day');
               } else if (diffInSeconds < secondsPerMonth) {
               return formatter.format(-Math.floor(diffInSeconds / secondsPerWeek), 'week');
               } else if (diffInSeconds < secondsPerYear) {
               return formatter.format(-Math.floor(diffInSeconds / secondsPerMonth), 'month');
               } else {
               return formatter.format(-Math.floor(diffInSeconds / secondsPerYear), 'year');
               }



               },


     },

     watch: {



     },
}
</script>

<style scoped>

.screen{
background: var(--grey-2);
padding-left: 8px;
padding-right: 8px;
}   
.gig{

background: var(--grey-1);    
padding: 16px;
margin-bottom: 16px;
border-radius: var(--border-radius-small); 
padding: 16px 16px;
margin-bottom: 8px;
cursor: pointer;
}



</style>
