<template>

	<transition name="slide-right" v-if="band">

		<div class="panel" v-if="this.active.panel4=='UserHomeBandsBandAdminsettings'" >


			<div class="debug">UserHomeBandsBandAdminsettings</div>

			<div class="header">

				<a class="left" @click="$setActiveProperty('panel4', '')"><LeftIcon/></a>

				<div class="title center">Admin {{$translate('SETTINGS')}} {{band.name}}</div>
			</div>


			<div class="content">

                    <div class="formgroup" :class="{ editing: isEditing }">
                         <div class="label">Name</div>
                         <div class="inputgroup">
                         <input  v-model="localband.name" type="text" :disabled="!isEditing">

                         </div>
                    </div>

 


                    <div :class="['formgroup',editing]">
                         <div class="label">Color</div>
                         <div class="inputgroup"  >
                              
                              <input v-model="localband.color" type="text" disabled >
                              <div @click="$setActiveProperty('panel5', 'UserHomeBandsBandAdminsettingsColor')" class="chevron"></div>

                              
                         </div>
                    </div>



                    <a class="button bottomright" v-if="!isEditing" @click="isEditing = true">Edit</a>
                    <a class="button bottomright" v-if="isEditing">Save</a>
                   
			</div>



		</div>

	</transition>

</template>


<script>


import LeftIcon from '@/components/common/icons/LeftIcon.vue';


import { mapState } from 'vuex';


export default{

data(){
     return {
          localband: null,
          isEditing: false,
     }
},

components: {
	LeftIcon,


},

methods: {




},

computed:{

	...mapState(['active','band']),

     editing(){


          if (this.isEditing){
               
               return 'editing'; 
          } else {

               return '';
          }



     },





},




watch: {
	
	'band.updated_at': {
		handler() {

			// Ik maak een kopie omdat ik de data naar de api wil sturen en de api de store wil laten updaten.
			
               if (this.band) {

                    this.localband = JSON.parse(JSON.stringify(this.band));
               }
               
 
		},
		immediate: true 
		}
	},
}

</script>


