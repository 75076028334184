<template>



     <div class="screen" >
          
          <div class="debug">GigHomeCreateAppointment</div>


     

               <div class="formgroup editing mb16">
                    <div class="label">Titel</div>
                    <input class="w100" v-model="venue" type="text">
               </div>

            



               <div class="formgroup editing">

                    <table class="dates" width="100%">

         

                         <tr>
                              <td colspan="5"><div class="label">Start</div></td>

                         </tr>

                         <tr>
                           
                           <td ><div class="label">day</div></td>
                           <td><div class="label">month</div></td>
                           <td><div class="label">year</div></td>
                           <td><div class="label">hour</div></td>
                           <td><div class="label">minute</div></td>
                      
                          </tr>
                         <tr>
                         
                         <td class="two"><input  v-model="startDay" @input="day = handleInput($event.target.value, 2)" type="text" placeholder=""></td>
                         <td class="two"><input  v-model="startMonth" @input="month = handleInput($event.target.value, 2)" type="text" placeholder=""></td>
                         <td class="four"><input  v-model="startYear" @input="year = handleInput($event.target.value, 4)" type="text" placeholder=""></td>
                         <td class="two"><input  v-model="startHour" @input="hour = handleInput($event.target.value, 2)" type="text" placeholder=""></td>
                         <td class="two"><input  v-model="startMinute" @input="minute = handleInput($event.target.value, 2)" type="text" placeholder=""></td>
                         </tr>

                         <tr>
                              <td colspan="5"><div class="label">End</div></td>

                         </tr>

                         <tr>
                           
                           <td ><div class="label">day</div></td>
                           <td><div class="label">month</div></td>
                           <td><div class="label">year</div></td>
                           <td><div class="label">hour</div></td>
                           <td><div class="label">minute</div></td>
                      
                      </tr>

                         <tr>
               
                         <td class="two"><input  v-model="endDay" @input="day = handleInput($event.target.value, 2)" type="text" placeholder=""></td>
                         <td class="two"><input  v-model="endMonth" @input="month = handleInput($event.target.value, 2)" type="text" placeholder=""></td>
                         <td class="four"><input  v-model="endYear" @input="year = handleInput($event.target.value, 4)" type="text" placeholder=""></td>
                         <td class="two"><input  v-model="endHour" @input="hour = handleInput($event.target.value, 2)" type="text" placeholder=""></td>
                         <td class="two" ><input  v-model="endMinute" @input="minute = handleInput($event.target.value, 2)" type="text" placeholder=""></td>
                         </tr>

                    </table>

               </div>


               <div class="w-100">

         
                    <a class="button fr" @click="submit">Save</a>


               </div>


                
               
               
     


             


     </div>





</template>


<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default{

data(){
return {

     startDay: '',
     startYear: '',
     startMonth: '',
     startHour: '',
     startMinute: '',
     endDay: '',
     endYear: '',
     endMonth: '',
     endHour: '',
     endMinute: '',
     city: '',
     venue: '',
     
}
},

components: {



},
computed:{

...mapState(['active','config']),







},



methods: {

     enforceNumeric(value) {
          // Verwijder alles dat geen cijfer is
          return value.replace(/[^\d]/g, '');
     },
     enforceMaxLength(value, maxLength) {
          // Beperk de waarde tot de maximale lengte
          return value.slice(0, maxLength);
     },
     handleInput(value, maxLength) {
          let result = this.enforceNumeric(value);
          return this.enforceMaxLength(result, maxLength);
     },


     setdays(){
          
          this.startDay = this.$store.state.active.currentDay;
          this.startMonth = this.$store.state.active.currentMonth;
          this.startYear = this.$store.state.active.currentYear;
          this.startHour = '18';
          this.startMinute = '00';

          this.endDay = this.$store.state.active.currentDay;
          this.endMonth = this.$store.state.active.currentMonth;
          this.endYear = this.$store.state.active.currentYear;
          this.endHour = '23';
          this.endMinute = '00';

     },

     formatDateTime(year, month, day, hour, minute) {
     // Creëer een Date object
     var second = 0;

     const date = new Date(year, month - 1, day, hour, minute, second);

     // Formatteer de datum en tijd
     const formattedDate = [
     date.getFullYear(),
     ('0' + (date.getMonth() + 1)).slice(-2),
     ('0' + date.getDate()).slice(-2)
     ].join('-');

     const formattedTime = [
     ('0' + date.getHours()).slice(-2),
     ('0' + date.getMinutes()).slice(-2),
     ('0' + date.getSeconds()).slice(-2)
     ].join(':');

     // Combineer de datum en tijd tot één string
     return formattedDate + ' ' + formattedTime;
},




async submit() {

     var post = {};

     post.task = 'createAppointment';
     post.usertoken = this.active.usertoken;
     post.sort = 'appointment';
     post.venue = this.venue;
     post.start = this.formatDateTime(this.startYear, this.startMonth, this.startDay, this.startHour, this.startMinute)
     post.end = this.formatDateTime(this.endYear, this.endMonth, this.endDay, this.endHour, this.endMinute)

     // console.log(post);

     this.$setActiveProperty('loading',true);

     axios.post(this.config.apiserver + 'gigs', post )

     .then(() => {

          // console.log(response.data);


          this.$setActiveProperty('loading',false);


          this.$store.dispatch('fetchGigs');
          this.$setActiveProperty('panel2','');


     })
     .catch(error => {
   if (error.response) {
      
       console.log(error.response.data);
       
   } else if (error.request) {
       
       console.log(error.request);
   } else {
       
       console.log('Error', error.message);
   }
});
},




},

watch: {


     'active.panel2': {



          handler() {

               if (this.active.panel2=="GigHomeCreate"){

                    this.setdays();

               }

               
          },
          immediate: true 
          }
},




}
</script>

<style scoped>

.screen {
height: calc(100dvh - 200px);


}




table .two{
width: 16%;

}

table .four{
width: 32%;

}

</style>
