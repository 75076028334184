<template>

	<transition name="slide-up" v-if="band">

		<div class="panel autoheight" v-if="this.active.panel5=='UserHomeBandsBandAdminsettingsColor'" >


			<div class="debug">UserHomeBandsBandTypes</div>

			<div class="header">

				<a class="right" @click="$setActiveProperty('panel5', '')"><DownIcon/></a>

				<div class="title">Colors</div>
			</div>


			<div class="content">


                    <a class="button grey w100" @click="clickHandler('red')">Red</a>
                    <a class="button grey w100" @click="clickHandler('yellow')">Yellow</a>
                    <a class="button grey w100" @click="clickHandler('green')">Green</a>
                    <a class="button grey w100" @click="clickHandler('blue')">Blue</a>
                    <a class="button grey w100" @click="clickHandler('orange')">Orange</a>
                    <a class="button grey w100" @click="clickHandler('purple')">Purple</a>
                    <a class="button grey w100" @click="clickHandler('pink')">Pink</a>
                    <a class="button grey w100" @click="clickHandler('brown')">Brown</a>
                    <a class="button grey w100" @click="clickHandler('darkblue')">Dark Blue</a>
     



			</div>


		</div>

	</transition>



</template>


<script>
import { mapState } from 'vuex';
import axios from 'axios';

import DownIcon from '@/components/common/icons/DownIcon.vue';


export default{

data(){
     return {

     }
},

components: {
     DownIcon
},
computed:{

	...mapState(['active','translate','band','config']),




},



methods: {


	clickHandler(color){

  

          if (this.config.debug==1){ console.log('updateBand'); }

	

		var post = {};

		post.task = 'updateBand';
		post.usertoken = this.active.usertoken;
          post.bandId = this.band.id;
          post.color = color;
	


		console.log(post);

		this.$setActiveProperty('loading',true);

          axios.post(this.config.apiserver + 'bands', post )

          .then(response => {

               console.log(response.data);

			this.$setActiveProperty('loading',false);
               this.$setActiveProperty('panel5', '');
			this.$store.dispatch('fetchBands');
               this.$store.dispatch('fetchGigs');
             


          })
          .catch(error => {
               if (error.response) {

               console.log(error.response.data);

               } else if (error.request) {

               console.log(error.request);
               } else {

               console.log('Error', error.message);
               }
          });



	}


},

watch: {



},
}
</script>

<style scoped>

.status-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.button.ghost {
    flex-grow: 1;
    margin-right: 8px;
}

</style>
