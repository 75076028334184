<template>



		<div class="screen" >
               
               <div class="debug">GigHomeCreateGig</div>


               <div v-if="bandsOwnedByUser.length>0">


                    <div  class="formgroup editing">
                         <div class="label">Band</div>
                         <div class="inputgroup" @click="$setActiveProperty('panel3', 'GigHomeCreateBands')">
                              <input readonly v-model="band.name" type="text">
                              <div class="chevron"></div>
                         
                         </div>
                    </div>



                    <div  class="formgroup editing">
                         <div class="label">Types</div>
                         <div class="inputgroup" @click="$setActiveProperty('panel3', 'GigHomeCreateTypes')">
                              <input  readonly v-model="type.name" type="text" >
                              <div class="chevron"></div>
                         </div>
                    </div>


                    <div  class="formgroup editing">
                         <div class="label">Statuses</div>
                         <div class="inputgroup" @click="$setActiveProperty('panel3', 'GigHomeCreateStatuses')">
                              <input readonly v-model="status.name" type="text" >
                              <div class="chevron"></div>
                         </div>
                    </div>

                    <div class="formgroup editing">
                         <div class="label">Venue</div>
                         <div class="inputgroup">
                              <input v-model="venue" type="text">
                              
                         </div>
                    </div>


                    <div class="formgroup editing">
                         <div class="label">City</div>
                         <div class="inputgroup">
                              <input  v-model="city" type="text">
                              
                         </div>
                    </div>





                    <div class="formgroup editing">

                         <table class="dates" width="100%">



                              <tr>
                                   <td colspan="5"><div class="label">Start</div></td>

                              </tr>

                              <tr>
                              
                              <td><div class="label">day</div></td>
                              <td><div class="label">month</div></td>
                              <td><div class="label">year</div></td>
                              <td><div class="label">hour</div></td>
                              <td><div class="label">minute</div></td>
                         
                              </tr>
                              <tr>
                              
                              <td class="two"><input  v-model="startDay" @input="day = handleInput($event.target.value, 2)" type="text" placeholder=""></td>
                              <td class="two"><input  v-model="startMonth" @input="month = handleInput($event.target.value, 2)" type="text" placeholder=""></td>
                              <td class="four"><input  v-model="startYear" @input="year = handleInput($event.target.value, 4)" type="text" placeholder=""></td>
                              <td class="two"><input  v-model="startHour" @input="hour = handleInput($event.target.value, 2)" type="text" placeholder=""></td>
                              <td class="two"><input  v-model="startMinute" @input="minute = handleInput($event.target.value, 2)" type="text" placeholder=""></td>
                              </tr>

                              <tr>
                                   <td colspan="5"><div class="label">End</div></td>

                              </tr>

                              <tr>
                              
                              <td ><div class="label">day</div></td>
                              <td><div class="label">month</div></td>
                              <td><div class="label">year</div></td>
                              <td><div class="label">hour</div></td>
                              <td><div class="label">minute</div></td>
                         
                         </tr>

                              <tr>

                              <td class="two"><input  v-model="endDay" @input="day = handleInput($event.target.value, 2)" type="text" placeholder=""></td>
                              <td class="two"><input  v-model="endMonth" @input="month = handleInput($event.target.value, 2)" type="text" placeholder=""></td>
                              <td class="four"><input  v-model="endYear" @input="year = handleInput($event.target.value, 4)" type="text" placeholder=""></td>
                              <td class="two"><input  v-model="endHour" @input="hour = handleInput($event.target.value, 2)" type="text" placeholder=""></td>
                              <td class="two" ><input  v-model="endMinute" @input="minute = handleInput($event.target.value, 2)" type="text" placeholder=""></td>
                              </tr>

                         </table>

                    </div>
                         
                    <div class="w-100 mb16">

                         <a class="button fr" @click="submitgig">Save</a>

                    </div>     


               </div>

               <div v-else>

                    Hoe heet je band?


                    <input v-model="bandname" type="text" class="">
				
				<a class="button fr" @click="submitband">Save</a>

          
     
               </div>



               

		</div>




</template>


<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default{

data(){
     return {

          startDay: '',
          startYear: '',
          startMonth: '',
          startHour: '',
          startMinute: '',
          endDay: '',
          endYear: '',
          endMonth: '',
          endHour: '',
          endMinute: '',
          city: '',
          venue: '',
          bandname: ''
          
     }
},

components: {



},
computed:{

	...mapState(['active','gig','bands','band','config','user']),

     bandsOwnedByUser(){

          // van welke bands is deze gebruiker owner?

          const bands = this.bands.filter(band => band.role === 'owner');

          const bandId = bands['0'].id;

          this.$setActiveProperty('bandId',bandId);

      

          return bands;


     },
     status(){

          if (this.active.formStatus){
               return this.active.formStatus;

          } else {

               return this.band.statuses['0'];
          }

     },

     type(){

          if (this.active.formType){
               return this.active.formType;

          } else {

               return this.band.types['0'];
          }

          
     },
     panelstate(){


          return this.active.panel2;
     },
  


},



methods: {

     enforceNumeric(value) {
          // Verwijder alles dat geen cijfer is
          return value.replace(/[^\d]/g, '');
     },
     enforceMaxLength(value, maxLength) {
          // Beperk de waarde tot de maximale lengte
          return value.slice(0, maxLength);
     },
     handleInput(value, maxLength) {
          let result = this.enforceNumeric(value);
          return this.enforceMaxLength(result, maxLength);
     },




     setdays(){


          console.log('setdays');
          
          
          this.startDay = this.$store.state.active.currentDay;
          this.startMonth = this.$store.state.active.currentMonth;
          this.startYear = this.$store.state.active.currentYear;
          this.startHour = '18';
          this.startMinute = '00';

          this.endDay = this.$store.state.active.currentDay;
          this.endMonth = this.$store.state.active.currentMonth;
          this.endYear = this.$store.state.active.currentYear;
          this.endHour = '23';
          this.endMinute = '00';

     },

     formatDateTime(year, month, day, hour, minute) {
          // Creëer een Date object
          var second = 0;

          const date = new Date(year, month - 1, day, hour, minute, second);

          // Formatteer de datum en tijd
          const formattedDate = [
          date.getFullYear(),
          ('0' + (date.getMonth() + 1)).slice(-2),
          ('0' + date.getDate()).slice(-2)
          ].join('-');

          const formattedTime = [
          ('0' + date.getHours()).slice(-2),
          ('0' + date.getMinutes()).slice(-2),
          ('0' + date.getSeconds()).slice(-2)
          ].join(':');

          // Combineer de datum en tijd tot één string
          return formattedDate + ' ' + formattedTime;
     },




     async submitgig() 
     {

          var post = {};

          post.task = 'createGig';
          post.usertoken = this.active.usertoken;
          post.sort = 'band';
          post.bandId = this.band.id;
          post.typeId = this.type.id;
          post.statusId = this.status.id;
          post.venue = this.venue;
          post.city = this.city;
          post.start = this.formatDateTime(this.startYear, this.startMonth, this.startDay, this.startHour, this.startMinute)
          post.end = this.formatDateTime(this.endYear, this.endMonth, this.endDay, this.endHour, this.endMinute)

          // console.log(post);

          this.$setActiveProperty('loading',true);

          axios.post(this.config.apiserver + 'gigs', post )

          .then(() => {

               this.$setActiveProperty('loading',false);


               this.$store.dispatch('fetchGigs');
               this.$setActiveProperty('panel2','');


          })
          .catch(error => {
               if (error.response) {

               console.log(error.response.data);

               } else if (error.request) {

               console.log(error.request);
               } else {

               console.log('Error', error.message);
               }
          });
     },
     
     

     async submitband() {

          var post = {};

          post.task = 'createBand';
          post.usertoken = this.active.usertoken;
          post.name = this.bandname;
          post.language = this.active.language;

          // console.log(post);

          axios.post(this.config.apiserver + 'bands', post )

          .then(response => {

               if (this.config.debug==1){

                    console.log(response.data);

               }

               


               this.$store.dispatch('fetchBands');
         


          })
          .catch(error => {
          console.error(error);



          });
     },




},

watch: {


     'active.panel2': {



		handler() {

               if (this.active.panel2=="GigHomeCreate"){

                    this.setdays();
         

               }

			
		},
		immediate: true 
		}
	},

    



}
</script>

<style scoped>

.screen{

    height: calc(100dvh - 130px);
 

}

table .two{
width: 16%;

}

table .four{
width: 32%;

}

</style>
