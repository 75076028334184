<template>



	<div class="screen" >



		<div :class="['formgroup',editing]">
			<div class="label">Notes</div>
			<div class="inputgroup">
				<textarea v-model="noteValue" class="w100"  type="text" />
				
			</div>
          </div>


	</div>


	<div class="footer">

		<a class="button bottomright" v-if="!this.active.isEditing" @click="this.active.isEditing = true">Edit</a>

		<a class="button bottomleft" v-if="this.active.isEditing" @click="this.active.isEditing = false">Annuleren</a>

		<a class="button bottomright" v-if="this.active.isEditing" @click="submit">Save</a>



	</div>



</template>


<script>



import { mapState } from 'vuex';
import axios from 'axios';

export default{

data(){
     return {
		localgig: null,

     }
},

components: {



},
computed:{

	...mapState(['active','gig','config']),


	editing(){


		if (this.active.isEditing){
			
			return 'editing'; 
		} else {

			return '';
		}



	},
	noteValue: {
      get() {
        return this.localgig.privatenote ? this.localgig.privatenote.note : '';
      },
      set(newValue) {
        if (this.localgig.privatenote) {
          this.localgig.privatenote.note = newValue;
        }
      }
    }




},



methods: {


	submit(){

		if (this.config.debug==1){ console.log('updateNote'); }

		this.this.active.isEditing = false;

		var post = {};

		post.task = 'updateNote';
		post.usertoken = this.active.usertoken;
		post.gigId = this.gig.id;
		post.privatenote = this.localgig.privatenote.note;


		this.$setActiveProperty('loading',true);

		axios.post(this.config.apiserver + 'gigs', post )

		.then(() => {

	

			this.$setActiveProperty('loading',false);
			this.$store.dispatch('fetchGigs');
		


		})
		.catch(error => {
			if (error.response) {

			console.log(error.response.data);

			} else if (error.request) {

			console.log(error.request);
			} else {

			console.log('Error', error.message);
			}
		});





	}



},

watch: {
	
	'active.gigId': {
		handler() {

			// Ik maak een kopie omdat ik de data naar de api wil sturen en de api de store wil laten updaten.
			this.localgig = JSON.parse(JSON.stringify(this.gig));
			
		},
		immediate: true 
		}
},
}
</script>

<style scoped>


.screen{
	height: calc(100dvh - 210px) ;


}

.down{

	fill: var(--grey-1)''
}

</style>
