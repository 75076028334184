<template>
	<transition name="slide-up">
		<div class="panel autoheight" v-if="this.active.panel1=='FilterHome'"  @touchstart="handleTouchStart" @touchend="handleTouchEnd">
			<div class="debug">FilterHome</div>

			<div class="header">
				<a class="right" @click="$setActiveProperty('panel1', '')"><DownIcon/></a>
				<div class="title">FilterHome</div>
			</div>

			<div class="content">



				<div class="formgroup mb16">

					<div class="inputgroup">
						Show Shared <Toggle class="right" v-model="showShared" @change="handleSharedCheckboxChange()" /> 

					</div>

				</div>  


				<div class="bold">Filter Bands</div>


				<div v-for="band in bands" :key="band.id">

				<div class="formgroup">

					<div class="inputgroup">
					{{ band.name }} <Toggle class="right" :value="isChecked(band.id)" @change="handleCheckboxChange(band.id)" />

					</div>

				</div>  

				</div>

				<br>

				Aantal items: {{ active.filterNumberOfGigsLeft }}


			</div>
		</div>
	</transition>
</template>
   
<script>
import { mapState } from 'vuex';
import DownIcon from '@/components/common/icons/DownIcon.vue';
import Toggle from '@vueform/toggle'

export default {
data() {
return {
selectedbands: [],
showShared: true,
}
},
components: {
Toggle,
DownIcon

},
computed: {
...mapState(['active','bands','translate']),
filterBandIds() {
return this.active.filterBandIds;
}
},
methods: 
{
	isChecked(bandId) {
		return this.filterBandIds.includes(bandId);
	},

	handleCheckboxChange(bandId)
	{
		if (this.isChecked(bandId)) {
		this.$setActiveProperty('filterBandIds', this.filterBandIds.filter(id => id !== bandId));
		} else {
		this.$setActiveProperty('filterBandIds', [...this.filterBandIds, bandId]);
		}

		this.$store.dispatch('fetchGigs');

	},


	handleSharedCheckboxChange(){

		this.$setActiveProperty('filterShowShared', this.showShared);
		this.$store.dispatch('fetchGigs');

	}
}

}
</script>
   
   <style scoped>
   </style>