import axios from 'axios';
import store from '../store';

export default {



	fetchGigChats({commit},container){

		if (store.state.config.debug==1){

               console.log('fetchGigChats');

          }

		// Ik bekijk welke chats er al geladen zijn.

		var highestId = 0;

		if (store.state.gig.id in store.state.gigchats) {
			
			var gigchats = store.state.gigchats[store.state.gig.id];


			highestId = gigchats.reduce((max, obj) => {
			return obj.id > max ? obj.id : max;
			}, 0);
			
			
		
		}



		const params = new URLSearchParams({

			task: 'fetchGigChats',
			usertoken: store.state.active.usertoken,
			gigId: store.state.gig.id,
			startId: highestId
	
			}).toString();

			console.log(params);
	
	
			commit('SET_ACTIVE_LOADING', true);
	
	
			axios.get(`${store.state.config.apiserver}gigchats?${params}`)
	
			.then(response => {
	
				commit('SET_ACTIVE_LOADING', false);
	
				// console.log(response.data.data);

				var chats = response.data.data;

				chats = parseChats(chats);
	
	
				var gigId = store.state.gig.id;


				commit('SET_GIGCHATS', { gigId, chats: chats });


				// Scroll naar beneden na het ophalen van de chats
				if (container) {

					container.scrollTop = container.scrollHeight + 200;
				}

		
	
			})
			.catch(error => {
				console.error(error);
	
	
	
	
	
			});


	},



	setBand({commit}) {


          if (store.state.config.debug==1){

               console.log('setBand');

          }


    

          var band = store.state.bands.find(obj => obj.id === store.state.active.bandId);


          if (band){
               commit('SET_BAND', band);
          } else {

               band = store.state.bands['0'];
               commit('SET_BAND', band);
          }



		
     },


	fetchBands({commit,dispatch}) {

          if (store.state.config.debug==1){

               console.log('fetchBands');

          }

		const params = new URLSearchParams({

		task: 'fetchBands',
		usertoken: store.state.active.usertoken

		}).toString();


          commit('SET_ACTIVE_LOADING', true);


          axios.get(`${store.state.config.apiserver}bands?${params}`)

          .then(response => {

               commit('SET_ACTIVE_LOADING', false);

			const bands = response.data.data;






			// console.log(response.data);

			commit('SET_BANDS', bands);

               // De eerste band als default nemen voor de app
               dispatch('setBand');

         

               // Het filter standaard instellen voor deze bands


               const bandIds = bands.map(band => band.id);

               commit('SET_BANDFILTER', bandIds);





          })
          .catch(error => {
               console.error(error);





          });
     },


	fetchUser({commit}) {

          if (store.state.config.debug==1){

               console.log('fetchUser');

          }

		const params = new URLSearchParams({

			task: 'fetchUser',
			usertoken: store.state.active.usertoken,

		}).toString();

          commit('SET_ACTIVE_LOADING', true);

          commit('UPDATE_FETCH_STATUS',  { dataset: 'user', result: 'ok' });


          axios.get(`${store.state.config.apiserver}users?${params}`)

          .then(response => {

               commit('SET_ACTIVE_LOADING', false);

               var user = response.data.data;
			commit('SET_USER', user);


               if (user.settings) {
                    const settings = JSON.parse(user.settings);
                
                    if (settings.darktheme) {
                        document.body.classList.add('dark');
                    } else {
                        document.body.classList.remove('dark');
                    }
               }




          })
          .catch(error => {
               console.error(error);




          });
     },




     fetchGigs({ commit,dispatch }) {

          if (store.state.config.debug==1){

               console.log('fetchGigs');

          }

		const params = new URLSearchParams({

		task: 'fetchGigs',
          usertoken: store.state.active.usertoken,

		}).toString();


          commit('SET_ACTIVE_LOADING', true);


          axios.get(`${store.state.config.apiserver}gigs?${params}`)

          .then(response => {

               commit('SET_ACTIVE_LOADING', false);

               var gigs = response.data.data;

               // console.log(response.data);


     
               var filteredBandIds = store.state.active.filterBandIds;
                   
               if(filteredBandIds==0){
                    
                    gigs = filterGigsBands(gigs, filteredBandIds);

               }

               gigs = filterGigsByShared(gigs);




               commit('SET_ACTIVE_NUMBEROFFILTEREDGIGS', gigs.length);
          
               
               gigs = getmusicianstatus(gigs);

               // console.log(gigs);
              


               var gigsparsed = parseGigs(gigs);

               commit('SET_GIGS', gigsparsed);

               var gigsbyweek = parseGigsByWeek(gigs);
               commit('SET_GIGSBYWEEK', gigsbyweek);

               // console.log(response.data);

               gigs = parseGigsByDay(gigs);

            
               commit('SET_GIGSBYDAY', gigs);

               commit('UPDATE_FETCH_STATUS',  { dataset: 'gigs', result: 'ok' });

			
               
               dispatch('setGig');


          })
          .catch(error => {

               console.log('error fetchGigs');
               console.error(error);


               commit('UPDATE_FETCH_STATUS',  { dataset: 'gigs', result: 'error' });

          });
     },

     setGig({commit,dispatch}) {

          if (store.state.config.debug==1){

               console.log('setGig');

          }


		var gigId = store.state.active.gigId;

          if (!gigId){

               gigId = 1219;
          }




          var thisgig = store.state.gigs.find(element => element.id === gigId);

		commit('SET_GIG', thisgig);


		if (thisgig && thisgig.band){

			commit('SET_ACTIVE_BANDID',thisgig.band.id);

			dispatch('setBand');

		}

	

	

	
     },


     fetchTranslations({ commit }) {

          if (store.state.config.debug==1){

               console.log('fetchTranslations');

          }

          commit('SET_ACTIVE_LOADING', true);

          axios.get(store.state.config.apiserver + 'translations')

          .then(response => {


               commit('SET_ACTIVE_LOADING', false);

               const translations = parseTranslations(response.data.data);



               commit('SET_TRANSLATIONS', translations);
               commit('UPDATE_FETCH_STATUS',  { dataset: 'translations', result: 'ok' });

          })
          .catch(error => {
               console.error(error);


               commit('UPDATE_FETCH_STATUS',  { dataset: 'translations', result: 'error' });

          });
     }

};

function parseChats(chats){


	const oneWeekAgo = new Date();
	oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

	return chats.map(chat => {
	const date = new Date(chat.created_at);

	const optionsDayOfWeek = { weekday: 'long' };
	const optionsDate = { year: 'numeric', month: '2-digit', day: '2-digit' };
	const optionsTime = { hour: '2-digit', minute: '2-digit' };

	return {
	...chat,
	dateTime: date.toLocaleTimeString('nl-NL', optionsTime),
	dateDate: date > oneWeekAgo ? date.toLocaleDateString('nl-NL', optionsDayOfWeek) : date.toLocaleDateString('nl-NL', optionsDate),
	};
	});


}

function parseTranslations(translations){

     const translationsByCode = {};


     translations.forEach(translation => {

          const code = translation.code;


          translationsByCode[code] = translation;

     });


     return translationsByCode;



}


function getmusicianstatus(gigs) {

   
     gigs = gigs.map((gig) => {
       if (!gig.musicians || !Array.isArray(gig.musicians)) {

         return gig;
       }
   
       const invitedCount = gig.musicians.filter(user => {
         if (!user.pivot || typeof user.pivot.status !== 'string') {

           return false;
         }
   
         return user.pivot.status === 'invited';
       }).length;
   
       const yesCount = gig.musicians.filter(user => user.pivot.status === 'yes').length;
       const noCount = gig.musicians.filter(user => user.pivot.status === 'no').length;
       const maybeCount = gig.musicians.filter(user => user.pivot.status === 'maybe').length;
   
       gig.musicianstatus = {
         invited: invitedCount,
         yes: yesCount,
         no: noCount,
         maybe: maybeCount
       };
   
       return gig;
     });
   
     return gigs;
}






function filterGigsByShared(gigs) {


	if (store.state.active.filterShowShared == false){


	return gigs.filter(gig => {



	if (gig.shared === false){

	return true;

	}





	});


	} else {


	return gigs;

	}


  
}


function filterGigsBands(gigs, filteredBandIds) {
     return gigs.filter(gig => {
       return gig.ownersbands.some(band => {
         return filteredBandIds.includes(band.id);
       });
     });
   }


function parseGigs(gigs) {

     gigs.sort((a, b) => new Date(a.start) - new Date(b.start));


     const gigsparsed = [];

    


     gigs.forEach(originalGig => {

          const gig = { ...originalGig };

   

          var date = new Date(originalGig.start);
      

          var formated = {};


          formated.year = date.getFullYear();
          formated.date = date.getDate();
          formated.dayNameShort = getDayNameShort(date.getDay());
          formated.dayNameLong= getDayNameLong(date.getDay());
          formated.monthNameShort = getmonthShort(date.getMonth() + 1);
          formated.monthNameLong = getmonthLong(date.getMonth() + 1);
          formated.time = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

          gig.dateStart = formated;


          date = new Date(originalGig.end);
      

          formated = {};

          formated.year = date.getFullYear();
          formated.date = date.getDate();
          formated.dayNameShort = getDayNameShort(date.getDay());
          formated.dayNameLong= getDayNameLong(date.getDay());
          formated.monthNameShort = getmonthShort(date.getMonth() + 1);
          formated.monthNameLong = getmonthLong(date.getMonth() + 1);
          formated.time = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

          gig.dateEnd = formated;


          // Aantal dagen uitrekenen

          const startDate = new Date(originalGig.start);
          const endDate = new Date(originalGig.end);

          var differenceInMilliseconds = endDate.getTime() - startDate.getTime();
          var differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);

          differenceInDays = Math.floor(differenceInDays);

          gig.numberofdays = differenceInDays + 1;


          gigsparsed.push(gig);

     });



     return gigsparsed;
}



function parseGigsByDay(gigs) {
     const gigsByDay = {};

     gigs.forEach(originalGig => {

          const startDate = new Date(originalGig.start);
          const endDate = new Date(originalGig.end);

          for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
               
               const year = date.getFullYear();
               const month = (date.getMonth() + 1).toString().padStart(2, '0');
               const day = date.getDate().toString().padStart(2, '0');

               const dayKey = `${year}-${month}-${day}`;

               if (!gigsByDay[dayKey]) {
                    gigsByDay[dayKey] = [];
               }

               // Maak een kopie van de gig om aan te passen
               const gig = { ...originalGig };

               // Formatteer de start- en eindtijd als "HH:MM"
               const startTime = `${startDate.getHours().toString().padStart(2, '0')}:${startDate.getMinutes().toString().padStart(2, '0')}`;
               const endTime = `${endDate.getHours().toString().padStart(2, '0')}:${endDate.getMinutes().toString().padStart(2, '0')}`;

               // Voeg de start- en eindtijd toe aan de gig
               gig.starttime = startTime;
               gig.endtime = endTime;

               // Bepaal het dayType
               if (startDate.toDateString() === endDate.toDateString()) {
                    gig.dayType = 'singleDay';
               } else if (startDate.toDateString() === date.toDateString()) {
                    gig.dayType = 'first';
               } else if (endDate.toDateString() === date.toDateString()) {
                    gig.dayType = 'last';
               } else {
                    gig.dayType = 'middle';
               }


               gig.month = month;
               gig.day = day;
               gig.year = year; 

               gigsByDay[dayKey].push(gig);
          }
     });

     return gigsByDay;
}


function parseGigsByWeek(gigs) {
     const gigsByWeek = {};
 
     gigs.forEach(originalGig => {
         const startDate = new Date(originalGig.start);
         const endDate = new Date(originalGig.end);
 
         for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
             const year = date.getFullYear();
             const weekNumber = getWeekNumber(date);
             const weekKey = `${year}-W${weekNumber.toString().padStart(2, '0')}`;
 
             if (!gigsByWeek[weekKey]) {
                 gigsByWeek[weekKey] = [];
             }
 
             const gigIndex = gigsByWeek[weekKey].findIndex(gig => gig.id === originalGig.id);
             if (gigIndex === -1) {  // Als de gig niet al in de week is, voeg het toe
                 const gig = { ...originalGig };
                 const startTime = `${startDate.getHours().toString().padStart(2, '0')}:${startDate.getMinutes().toString().padStart(2, '0')}`;
                 const endTime = `${endDate.getHours().toString().padStart(2, '0')}:${endDate.getMinutes().toString().padStart(2, '0')}`;
 
                 gig.starttime = startTime;
                 gig.endtime = endTime;
 
                 // Bepaal het dayType gebaseerd op de datum in vergelijking met de start- en einddatum
                 if (startDate.toDateString() === endDate.toDateString()) {
                     gig.dayType = 'singleDay';
                 } else if (startDate.toDateString() === date.toDateString()) {
                     gig.dayType = 'first';
                 } else if (endDate.toDateString() === date.toDateString()) {
                     gig.dayType = 'last';
                 } else {
                     gig.dayType = 'middle';
                 }
 
                 gigsByWeek[weekKey].push(gig);
             }
         }
     });
 
     return gigsByWeek;
 }
 
 function getWeekNumber(d) {
     d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
     d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
     const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
     return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
 }



 function getmonthShort(month){

     if (month==1){ return store.state.translate.JAN_SHORT[store.state.active.language]; }
     if (month==2){ return store.state.translate.FEB_SHORT[store.state.active.language]; }
     if (month==3){ return store.state.translate.MAR_SHORT[store.state.active.language]; }
     if (month==4){ return store.state.translate.APR_SHORT[store.state.active.language]; }
     if (month==5){ return store.state.translate.MAY_SHORT[store.state.active.language]; }
     if (month==6){ return store.state.translate.JUN_SHORT[store.state.active.language]; }
     if (month==7){ return store.state.translate.JUL_SHORT[store.state.active.language]; }
     if (month==8){ return store.state.translate.AUG_SHORT[store.state.active.language]; }
     if (month==9){ return store.state.translate.SEP_SHORT[store.state.active.language]; }
     if (month==10){ return store.state.translate.OCT_SHORT[store.state.active.language]; }
     if (month==11){ return store.state.translate.NOV_SHORT[store.state.active.language]; }
     if (month==12){ return store.state.translate.DEC_SHORT[this.active.language]; }

     return month;
}

function getmonthLong(month){

     if (month==1){ return store.state.translate.JAN_LONG[store.state.active.language]; }
     if (month==2){ return store.state.translate.FEB_LONG[store.state.active.language]; }
     if (month==3){ return store.state.translate.MAR_LONG[store.state.active.language]; }
     if (month==4){ return store.state.translate.APR_LONG[store.state.active.language]; }
     if (month==5){ return store.state.translate.MAY_LONG[store.state.active.language]; }
     if (month==6){ return store.state.translate.JUN_LONG[store.state.active.language]; }
     if (month==7){ return store.state.translate.JUL_LONG[store.state.active.language]; }
     if (month==8){ return store.state.translate.AUG_LONG[store.state.active.language]; }
     if (month==9){ return store.state.translate.SEP_LONG[store.state.active.language]; }
     if (month==10){ return store.state.translate.OCT_LONG[store.state.active.language]; }
     if (month==11){ return store.state.translate.NOV_LONG[store.state.active.language]; }
     if (month==12){ return store.state.translate.DEC_LONG[this.active.language]; }

     return month;
}


function getDayNameShort(day){


     if (day==0){ return store.state.translate.SUN_SHORT[store.state.active.language]; }
     if (day==1){ return store.state.translate.MON_SHORT[store.state.active.language]; }
     if (day==2){ return store.state.translate.TUE_SHORT[store.state.active.language]; }
     if (day==3){ return store.state.translate.WED_SHORT[store.state.active.language]; }
     if (day==4){ return store.state.translate.THU_SHORT[store.state.active.language]; }
     if (day==5){ return store.state.translate.FRI_SHORT[store.state.active.language]; }
     if (day==6){ return store.state.translate.SAT_SHORT[store.state.active.language]; }
 


     return day;

}


function getDayNameLong(day){


     if (day==0){ return store.state.translate.SUN_LONG[store.state.active.language]; }
     if (day==1){ return store.state.translate.MON_LONG[store.state.active.language]; }
     if (day==2){ return store.state.translate.TUE_LONG[store.state.active.language]; }
     if (day==3){ return store.state.translate.WED_LONG[store.state.active.language]; }
     if (day==4){ return store.state.translate.THU_LONG[store.state.active.language]; }
     if (day==5){ return store.state.translate.FRI_LONG[store.state.active.language]; }
     if (day==6){ return store.state.translate.SAT_LONG[store.state.active.language]; }
 


     return day;

}

 

