<template>

	<transition name="slide-up">

		<div class="panel autoheight" v-if="this.active.panel3=='GigHomeCreateStatuses'" >
               
               <div class="debug">GigHomeCreateStatusses</div>

			<div class="header">
				<a class="right" @click="$setActiveProperty('panel3', '')"><DownIcon/></a>
				<div class="title">Select Status</div>
			</div>


               <div class="content">


                    <div v-for="type in band.statuses" :key="type.id">

                         <div class="button grey w100 mb8" @click="handleBandClick(type)">
                              {{type.name}} 
                         </div>

                    </div>
                    
                    
    

               </div>

		</div>

	</transition>

</template>


<script>


import { mapState } from 'vuex';
import DownIcon from '@/components/common/icons/DownIcon.vue';

export default{

data(){
     return {
          DownIcon
     }
},

components: {

     DownIcon

},
computed:{

	...mapState(['active','bands','band']),




},



methods: {


     handleBandClick(status){

          this.$setActiveProperty('formStatus', status);
          this.$setActiveProperty('panel3', '');

     }




},

watch: {



},
}
</script>

<style scoped>



</style>
