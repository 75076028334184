<template>



	<div class="screen" >



		Location


	</div>



</template>


<script>



import { mapState } from 'vuex';

export default{

data(){
     return {

     }
},

components: {



},
computed:{

	...mapState(['active']),




},



methods: {


},

watch: {



},
}
</script>

<style scoped>

</style>
