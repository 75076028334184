<template>

	<transition name="slide-right">

		<div class="panel" v-if="this.active.panel4=='UserHomeBandsBandMembers'" >

			<div class="debug">UserHomeBandsBandMembers</div>

			<div class="header">

                    <a class="left" @click="$setActiveProperty('panel4', '')"><LeftIcon/></a>
                    <div class="title center">Members {{band.name}}</div>


               </div>

               <div class="content">


				<div class="section">


					<table class="form">


				
					<tr v-for="user in this.band.users" :key="user.id">

						<td>{{user.email }}</td>
						<td>{{user.pivot.role }}</td>

					</tr>

					</table>




				</div>

				
				<h3>New Members</h3>

                    <input v-model="email" type="text" placeholder="Email"><br><br>

                    <a class="button" @click="submit">Add Member</a>



               </div>

		

		</div>

	</transition>

</template>


<script>

import axios from 'axios';



// https://github.com/vueform/toggle

import { mapState } from 'vuex';
import LeftIcon from '@/components/common/icons/LeftIcon.vue';


export default{

data(){
     return {
		email: '',
     }
},

components: {

	LeftIcon 
 
},
computed:{

	...mapState(['active','config','user','band']),

  

},



methods: {


	async submit() {

		var post = {};

		post.task = 'addMusician';
		post.usertoken = this.active.usertoken;
          post.bandId = this.band.id;
          post.email = this.email;
 
		

		// console.log(post);

          this.$setActiveProperty('loading',true);

		axios.post(this.config.apiserver + 'users', post )

		.then(response => {

               this.$setActiveProperty('loading',false);

			console.log(response.data);

			this.email = '';

			this.$store.dispatch('fetchBands');

			


		})
		.catch(error => {
		console.error(error);



		});
	},

     





},

watch: {





},
}
</script>



<style scoped>


.section{
border-radius: var(--border-radius-big);
background: var(--grey-2);
padding: 8px 16px;
margin-bottom: 16px;
}

</style>
