<template>



	<div class="screen" >





		<div :class="['formgroup',editing]">
			<div class="label">Venue</div>
			<div class="inputgroup">
				<input class="w100" v-model="localgig.venue" type="text" :disabled="!this.active.isEditing">
				
			</div>
          </div>



		<div :class="['formgroup',editing]">
			<div class="label">City</div>
			<div class="inputgroup">
				<input class="w100" v-model="localgig.city" type="text" :disabled="!this.active.isEditing">
				
			</div>
          </div>


		<div :class="['formgroup',editing]">
			<div class="label">Type</div>
			<div class="inputgroup" >
				<input v-model="type.name" type="text" disabled >
				<div @click="$setActiveProperty('panel3', 'GigHomeCreateTypes')" class="chevron"></div>
			</div>
          </div>


		<div :class="['formgroup',editing]">
			<div class="label">Status</div>
			<div class="inputgroup"  >
				
				<input v-model="status.name" type="text" disabled >
				<div @click="$setActiveProperty('panel3', 'GigHomeCreateStatuses')" class="chevron"></div>

				
			</div>
          </div>

		


		<span v-for="info in infos" :key="info.id">


			<div :class="['formgroup',editing]">
				<div class="label">{{ info.name }}</div>
				<div class="inputgroup" >
					<div :id="`input-${info.id}`" :contenteditable="this.active.isEditing"  :class="{ editing: this.active.isEditing }" @input="updateInfo($event, info.id)" class="editable-content">{{ info.value }}
					</div>
				</div>
			</div>	


			



		</span>






	</div><!-- screen -->

	<div v-if="band.role=='owner'" class="footer">

		<a class="button bottomright" v-if="!this.active.isEditing" @click="this.active.isEditing = true">Edit</a>
		
		<a class="button bottomleft" v-if="this.active.isEditing" @click="this.active.isEditing = false">Annuleren</a>
	
		<a class="button bottomright" v-if="this.active.isEditing" @click="submit">Save</a>



	</div>












</template>


<script>



import { mapState } from 'vuex';
import axios from 'axios';


export default{

data(){
     return {
		localgig: null,

     }
},

components: {



},
computed:{

	...mapState(['active','gig','band','config']),

	editing(){


		if (this.active.isEditing){
			
			return 'editing'; 
		} else {

			return '';
		}



	},


	status(){

		if (this.active.formStatus){
			return this.active.formStatus;

		} else {

			return this.localgig.status;
		}

	},

	type(){

		if (this.active.formType){

			return this.active.formType;

		} else {

			return this.localgig.type;
		}


	},

	infos(){

		// Door de bandinfos lopen en kijken of er en waarde is definieerd


	

		try {
		var infos = JSON.parse(this.band.infos);
		} catch (error) {
		infos = [];
		}

		try {
			var giginfos = JSON.parse(this.gig.infos);
		} catch (error) {
			giginfos = null;
		}


		if (giginfos) {

			infos = infos.map(info => {


			const giginfo = giginfos.find(element => element.name === info.name);

			if (giginfo){
			return { ...info, value: giginfo.value };
			} else {
			return { ...info, value: ''};
			}

		});





		}


	

		return infos;



	}


	

},



methods: {


	updateInfo(event, id) {
	const updatedValue = event.target.innerText;
	const info = this.infos.find(info => info.id === id);
	if (info) {
	info.value = updatedValue;
	}
	},


	submit(){

		if (this.config.debug==1){ console.log('updateGig'); }

		this.this.active.isEditing = false;

		var post = {};

		post.task = 'updateGig';
		post.usertoken = this.active.usertoken;
		post.venue = this.localgig.venue;
		post.city = this.localgig.city;
		post.gigId = this.gig.id;
		post.infos = JSON.stringify(this.infos);


		// console.log(post);

		this.$setActiveProperty('loading',true);

          axios.post(this.config.apiserver + 'gigs', post )

          .then(response => {

               console.log(response.data);

			this.$setActiveProperty('loading',false);
			this.$store.dispatch('fetchGigs');
             


          })
          .catch(error => {
               if (error.response) {

               console.log(error.response.data);

               } else if (error.request) {

               console.log(error.request);
               } else {

               console.log('Error', error.message);
               }
          });





	}


},

watch: {
	
	'active.gigId': {
		handler() {

			// Ik maak een kopie omdat ik de data naar de api wil sturen en de api de store wil laten updaten.
			this.localgig = JSON.parse(JSON.stringify(this.gig));
			
		},
		immediate: true 
		}
},
}
</script>

<style scoped>



.screen{
	height: calc(100dvh - 210px) ;


}

</style>
