<template>


     <div v-if="gig">

          <div v-if="gig.shared == false" >


               <div class="gig" v-if="gig.sort=='band'">



                    <div class="nowrap">
                

                         <span class=" small tijd" v-if="gig.dayType=='first'">{{ gig.starttime }}</span>
                         <span class=" small tijd" v-if="gig.dayType=='last'">-{{ gig.endtime }}</span> 
                         <span class="small tijd"  v-if="gig.dayType=='middle'">dag</span>
                         <span class="small tijd" v-if="gig.dayType=='singleDay'">{{ gig.starttime }}</span>


					<span v-if="gig.userstatus=='admin'" class="bullit">a</span>

                         <span class="bold small">{{gig.band.name}}&nbsp;</span>

                         <span class=" small">{{gig.city}}&nbsp;</span>


                    </div>     




               </div>   


               <div class="gig" v-if="gig.sort=='appointment'">



                    <div class="nowrap">

                 
                         <span class=" small tijd" v-if="gig.dayType=='first'">{{ gig.starttime }}</span>
                         <span class=" small tijd" v-if="gig.dayType=='last'">-{{ gig.endtime }}</span> 
                         <span class="small tijd"  v-if="gig.dayType=='middle'">dag</span>
                         <span class="small tijd" v-if="gig.dayType=='singleDay'">{{ gig.starttime }}</span>



                         <span class="bold small">{{gig.venue}}&nbsp;</span>

                       


                    </div>     




               </div>   



          </div>     
          


                                          



          <div class="gig nowrap" v-if="gig.shared == true">

        


                    <div  v-if="gig.sort=='band'">


                         <span v-for="band in gig.ownersbands" :key="band.id" :class="['bullit', band.color]">
                         {{ band.name[0] }}
                         </span>


                         <span class="small tijd" v-if="gig.dayType=='first'">{{ gig.starttime }}&nbsp;</span>
                         <span class="small tijd" v-if="gig.dayType=='last'">tot {{ gig.endtime }}&nbsp;</span> 
                         <span class="small tijd"  v-if="gig.dayType=='middle'">DAG&nbsp;</span>
                         <span class="small tijd" v-if="gig.dayType=='singleDay'">{{ gig.starttime }}&nbsp;</span>

                         <span class="small bold" >{{gig.user.name}}&nbsp;</span>
                         

                         <span class="small" v-for="musician in gig.musicians" :key="musician.id">
                              
                              <span>{{ musician.name }} &nbsp;</span>

                         </span>


                         <span class="small" >{{gig.band.name}}&nbsp;</span>
                 
                    



                    </div>     


                    <div  v-if="gig.sort=='appointment'">


                         <span v-for="band in gig.ownersbands" :key="band.id" :class="['bullit', band.color]">
                         {{ band.name[0] }}
                         </span>

                
                         <span class=" small tijd" v-if="gig.dayType=='first'">{{ gig.starttime }}</span>
                         <span class=" small tijd" v-if="gig.dayType=='last'">-{{ gig.endtime }}</span> 
                         <span class="small tijd"  v-if="gig.dayType=='middle'">dag</span>
                         <span class="small tijd" v-if="gig.dayType=='singleDay'">{{ gig.starttime }}</span>

                         <span class="small bold">{{gig.user.name}}&nbsp;</span>
                    
                         <span class="small">{{gig.venue}}&nbsp;</span>

                    </div>


 


          </div>


          


     </div>




                                        


</template>


<script>

import { mapState } from 'vuex';

export default{

data(){
     return {
   
     }
},

props: {
     gig: Object,
},

components: {

},
computed:{

	...mapState(['active']),

 




},



methods: {




},

watch: {



},
}
</script>

<style scoped>

.gig{

line-height: 110%;

}




.bullit{
background: var(--blauw);
color: #fff;
font-weight: 700;
font-size: 9px;
line-height: 10px;
height: 12px;
width: 12px;
padding-top: 1px;
padding-left: 0px;
border-radius: 8px;
text-align: center;
display: inline-block;
box-sizing: border-box;
position: relative;
top: -1px;
margin-right: 2px;
}



.tijd{
text-transform: uppercase;
font-weight: 200;
margin-right: 5px;
width: 32px;
display: inline-block;
font-size: 11px;
}

</style>
