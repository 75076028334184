<template>

	<transition name="slide-up">

		<div class="panel" v-if="this.active.panel2=='GigHomeCreate'" >
			
			<div class="debug">GigHomeCreate</div>

			<div class="header">
				<a class="right" @click="$setActiveProperty('panel2', '')"><DownIcon/></a>
				<div class="title">New</div>
			</div>


			<div >

					<div class="slidemenu">

						<div class="item" @click="gotoscreen(0)" :class="{ active: this.active.gigCreateScreen === 0 }" >
							<div class="text">
								New Gig 
							</div>
						</div>

						<div class="item" @click="gotoscreen(1)" :class="{ active: this.active.gigCreateScreen === 1 }">
							<div class="text">
									New Appointment
							</div>

						</div>

			

					</div>

					<swiper  :onSwiper="setScreensSwiper" @slideChange="updateScreens" slidesPerView="1" spaceBetween="50" class="agenda-swiper" >

						<swiper-slide>
							<GigHomeCreateGig/>
						</swiper-slide>

						<swiper-slide>

							

							<GigHomeCreateAppointment/>
						
						</swiper-slide>

				



					</swiper>




			</div>

		


		</div><!--panel-->

	</transition>

</template>


<script>
import GigHomeCreateGig from '@/components/gig/GigHomeCreateGig.vue';
import GigHomeCreateAppointment from '@/components/gig/GigHomeCreateAppointment.vue';


import DownIcon from '@/components/common/icons/DownIcon.vue';


import { Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';

import { mapState } from 'vuex';

export default{

data(){
     return {

     }
},

components: {

	Swiper,
	SwiperSlide,
	GigHomeCreateGig,
     GigHomeCreateAppointment,
	DownIcon

},
computed:{

	...mapState(['active','gig','translate']),


},



methods: {

	setScreensSwiper(swiper) {
		this.screensswiper = swiper;

	},

	updateScreens(swiper){

		const activeIndex = swiper.activeIndex;
		this.$setActiveProperty('gigCreateScreen', activeIndex);

	},


	gotoscreen(screen){

		this.screensswiper.slideTo(screen);

	},
	

},

watch: {


	'active.panel2': {



		handler() {

			if (this.active.panel2=="GigHomeCreate"){

				this.$setActiveProperty('gigCreateScreen', 0);

			}

			
		},
		immediate: true 
		}



},
}
</script>

<style scoped>




.swiper {
	height: calc(100vh - 180px);
     overflow: hidden;
}

</style>
