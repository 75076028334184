<template>



          <div class="overlay vier" v-if="show" :class="{ 'open': show }" @click="$setActiveProperty('panel5', '')" >

          
               

          </div>




</template>

<script>

import { mapState } from 'vuex';

export default{

data(){
     return {

     }
},

components: {

},
computed:{

	...mapState(['active']),

     show(){

     var panel = this.active.panel5;

     if (panel){
          return true;
     } else {

          return false;
     }


     }





},



methods: {


},

watch: {



},
}
</script>

<style scoped>

</style>
