<template>

	<transition name="slide-up">

		<div class="panel autoheight"  v-if="this.active.panel2=='AnswerHomeMaybe'" >
			
			<div class="debug">AnswerHomeYes</div>

			<div class="header">
				<a class="right" @click="$setActiveProperty('panel2', '')"><DownIcon/></a>
				<div class="title">Bevestig</div>
			</div>


			<div class="content centertext">


                    Bevestig dat je beschikbaar bent voor deze gig.<br/>
             

                    <textarea v-model="note" placeholder="Opmerkingen voor de beheerder"></textarea><br>

                    

                    <a class="button"  @click="submit()">Misschien</a><br>

                    
                                   
                                         
		

		
        
			</div>

		


		</div><!--panel-->

	</transition>

</template>


<script>
import DownIcon from '@/components/common/icons/DownIcon.vue';

import { mapState } from 'vuex';
import axios from 'axios';

export default{

data(){
     return {

          note : '',

     }
},

components: {


     DownIcon

},
computed:{

	...mapState(['active','gig','translate','config']),



},



methods: {

     submit(){

          if (this.config.debug==1){ console.log('updateGig'); }

          this.isEditing = false;

          var post = {};

          post.task = 'updateMusician';
          post.usertoken = this.active.usertoken;
          post.gigId = this.gig.id;
          post.status = 'maybe';
          post.note = this.note;
     

          console.log(post);

          this.$setActiveProperty('loading',true);

          axios.post(this.config.apiserver + 'gigs', post )

          .then(response => {

               console.log(response.data);

               this.$setActiveProperty('loading',false);

               this.$store.dispatch('fetchGigs');

               this.$setActiveProperty('panel1','');

               this.note = '';
          


          })
          .catch(error => {
               if (error.response) {

               console.log(error.response.data);

               } else if (error.request) {

               console.log(error.request);
               } else {

               console.log('Error', error.message);
               }
          });





     },


	


},

watch: {

   
}
}
</script>

<style scoped>


textarea{
     width: 300px;
     height: 100px;
     margin: 10px 0;
}

</style>
