<template>

	<transition name="slide-right" v-if="band">

		<div class="panel" v-if="this.active.panel4=='UserHomeBandsBandStatuses'" >


			<div class="debug">UserHomeBandsBandStatuses</div>

			<div class="header">

				<a class="left" @click="$setActiveProperty('panel4', '')"><LeftIcon/></a>

				<div class="title center">{{band.name}}</div>
			</div>


			<div class="content">


				<div class="title">{{$translate('UserHomeBandsBandStatuses_Title')}}</div>
				<div class="text">{{$translate('UserHomeBandsBandStatuses_Text')}}</div>



				<div v-for="status in band.statuses" :key="status.id" class="status-container">

					<div class="button grey w100 mb8 mr8" >
						{{status.name}}
					</div>

					<span @click="statusClickHandler(status)"><DeleteIcon/></span>


				</div>

				<div class="button bottomright" @click="$setActiveProperty('panel5', 'UserHomeBandsBandStatusesCreate')">
					Create Status
				</div>

			</div>


		</div>

	</transition>



</template>


<script>
import { mapState } from 'vuex';
import LeftIcon from '@/components/common/icons/LeftIcon.vue';
import DeleteIcon from '@/components/common/icons/DeleteIcon.vue';

export default{

data(){
     return {

     }
},

components: {
	LeftIcon,
	DeleteIcon
},
computed:{

	...mapState(['active','translate','band']),




},



methods: {


	statusClickHandler(status){


		this.$setActiveProperty('status', status);
		this.$setActiveProperty('panel5', 'UserHomeBandsBandStatusesDelete');



	}


},

watch: {



},
}
</script>

<style scoped>

.status-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.button.ghost {
    flex-grow: 1;
    margin-right: 8px;
}

</style>
