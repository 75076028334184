import { createRouter, createWebHistory } from 'vue-router';

import MaxMobile from '@/components/MaxMobile.vue'



const routes = [
  {
     path: '/',
     name: 'home',
     component: MaxMobile
  }
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router
