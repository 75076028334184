<template>
	<div class="screen">


		<div class="chatscontainer" ref="chatscontainer">

		<div v-for="item in groupedChats" :key="item.id">
			
			<span v-if="item.type === 'date'">
				<div class="date bold">{{ item.date }}</div>
			</span>

			<div v-if="item.type === 'chat'"  class="chatcontainer">


				<div v-if="item.user_id === this.user.id" class="chat right ">
					
		
					{{ item.text }}
					<div class="small tar">{{ item.dateTime }}</div>


				</div>
				
				<div v-if="item.user_id != this.user.id" class="chat">

					<div class="name small bold">{{ item.user.name }}</div>
					{{ item.text }}
					<div class="small tar">{{ item.dateTime }}</div>
				

				</div>

		
			</div>
		</div>

		</div>




		<div class="bottom">
			<div class="editable-content editing" id="text" @keyup.enter="submit"  contenteditable ref="editableContent" @input="updateText">{{ text }}</div>
			
			<SendIcon @click="submit" />
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import SendIcon from '@/components/common/icons/SendIcon.vue';
import { mapState } from 'vuex';


export default {
	setup(){
	


	},
data() {
	return {
	text: '',
	maxLength: 2500,
	};
},
components: {
	SendIcon,
},
computed: {
	...mapState(['active', 'gig', 'translate', 'config','gigchats','user']),

	

	groupedChats() {
		const chats = this.gigchats[this.gig.id] || [];
		let previousDate = '';

		return chats.reduce((acc, chat) => {
		if (chat.dateDate !== previousDate) {
		acc.push({ type: 'date', date: chat.dateDate });
		previousDate = chat.dateDate;
		}
		acc.push({ ...chat, type: 'chat' });
		return acc;
		}, []);
    }





},
methods: {

	startEventStream() {


		const eventSource = new EventSource(this.config.apiserver + 'gigchatsstream/' + this.gig.id);

		eventSource.onmessage = (event) => {
			const data = JSON.parse(event.data);

			
			console.log('stream:' + data);

			this.fetchGigChats();
		};
	},
	fetchGigChats(){

		const container = this.$refs.chatscontainer;

		this.$store.dispatch('fetchGigChats',container);

	},

	updateText() {
		
		const content = this.$refs.editableContent.innerText;

		if (content.length <= this.maxLength) {
		this.text = content;
		} else {
	
		this.$refs.editableContent.innerText = this.text;
		}
	},
	submit() 
	{
		
		if (this.text.length==0) {


			return;

		}
		
		var post = {};
		post.task =  'newChat';
		post.usertoken = this.active.usertoken;
		post.text =  this.text;
		post.gigId = this.gig.id;


		axios.post(this.config.apiserver + 'gigchats', post)
		.then(response => {
		if (this.config.debug == 1) 
		{
			console.log('STREAM: ' + response.data);
			this.text="";
	

		}
		})
		.catch(error => 
		{
			console.error(error);
		});
	},
},
mounted(){



	this.fetchGigChats();


},
created(){


	this.startEventStream();

}
};
</script>

<style scoped>
.screen {
height: calc(100dvh - 210px);
position: relative;
}

.chatscontainer{
position: absolute;
top: 15px;
left: 15px;
right: 15px;
bottom: 70px;
padding-right: 15px;
padding-bottom: 100px;
overflow: hidden;
overflow-y: scroll;
}

.chatcontainer{

width: 100%;
overflow: hidden;
}

.date{

padding-left: 15px;
padding-bottom: 15px;
padding-top: 5px;
}

.chat{
background: var(--grey-2);
margin-bottom: 15px;
padding: 5px 15px;
display: inline-block;
border-radius: 15px;
}

.chat.right{
float: right;
}

.bottom {
position: absolute;
bottom: 0;
left: 0;
right: 0;
box-sizing: border-box;
}

.bottom #text {
position: absolute;
left: 15px;
bottom: 15px;
right: 60px;
width: auto;
}

.bottom .icon {
position: absolute;
right: 15px;
bottom: 15px;
width: 40px;
height: 40px;
}
</style>
