<template>

	<transition name="slide-up">

		<div class="panel autoheight boxshadow" v-if="this.active.panel5=='UserHomeBandsBandStatusesDelete'" >
			<div class="debug">userhomebandsbandStatusesDelete</div>
			<div class="header">


				<div class="title">Status verwijderen</div>

				<a class="right" @click="$setActiveProperty('panel5', '')"><DownIcon /></a>
			</div>

			<div class="content centertext">

				Status verwijderen: {{active.status.name}}<br>
				<a class="button bottomright" @click="submit">Verwijderen</a>

				<a class="button ghost bottomleft" @click="submit">Cancel</a>
			</div>

		</div>

	</transition>

</template>


<script>

import axios from 'axios';

import { mapState } from 'vuex';
import DownIcon from '@/components/common/icons/DownIcon.vue';



export default{

data(){
     return {

     }
},

components: {
	DownIcon 
},

methods: {


	async submit() {

		var post = {};

		post.task = 'deleteStatus';
		post.usertoken = this.active.usertoken;
		post.statusId = this.active.status.id;

		// console.log(post);

		axios.post(this.config.apiserver + 'statuses', post )

		.then(() => {


			this.$store.dispatch('fetchBands');
			this.$setActiveProperty('panel5','');


		})
		.catch(error => {
		console.error(error);



		});
	},






},

computed:{

	...mapState(['active','config']),




},




watch: {



},
}
</script>

<style scoped>



</style>
