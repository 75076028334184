<template>

	

	<div  v-if="active.loginstate=='login'">

		<UserLogin />

	</div>

	<div  v-if="active.loginstate=='hashnotok'">

		Hash not ok!

	</div>



	<div  v-if="active.loginstate=='checkhash'">

		<MaxLoading/>

	</div>


	<div  v-if="active.loginstate=='hashok' && dataloaded">

		<MaxMobile />

	</div>





</template>



<script>

import MaxMobile from '@/components/MaxMobile.vue'
import MaxLoading from '@/components/MaxLoading.vue'
import UserLogin from '@/components/UserLogin.vue'

// import Cookies from 'js-cookie'
import axios from 'axios';

import { mapState } from 'vuex'

export default{

data(){
     return {
		state: 'login'
     }
},

components: {
	MaxMobile,
	UserLogin,
	MaxLoading
},



methods: {


	checklogin(){



		const params = new URLSearchParams(window.location.search);
		const hash = params.get('hash');


		// Kijken of de hash nog geldig is.

		


		if(hash) {

			this.$setActiveProperty('loginstate', 'checkhash');

			if (this.config.debug==1){ console.log('checkHash'); }

			var post = {};

			post.task = 'check_hash';
			post.hash = hash;

			console.log(post);

			axios.post(this.config.apiserver + 'login', post )

			.then(response => {

				console.log(response);
				
				if (response.data.status == 'success') {


					this.$setActiveProperty('loginstate', 'hashok');

					// De hash is ok, we gaan inloggen

					if (this.config.debug==1){ console.log('logged in by hash'); }

					this.$setActiveProperty('usertoken', response.data.data.usertoken);
					this.$setActiveProperty('loggedin', true);


				} else {

					this.$setActiveProperty('loginstate', 'hashnotok');

					if (this.config.debug==1){ console.log('Hash is not ok'); }


				}


			})
			.catch(error => {

				console.error(error);

				if (this.config.debug==1){ console.log('NOT logged in'); }

			});




		}


		

	},




	


},

computed:{

     ...mapState(['fetched','config','active','gigs']),

     dataloaded(){

          // Ik controleer of alle data geladen is die nodig is om de app te laten werken.
          // De datasets worden aangegeven in state.js

          var fetchedOk = [];

          this.fetched.forEach(item => {

               if (item.result=='ok'){

                    fetchedOk.push(item.dataset);
               }

          });

          var dataloaded = true;

          this.config.fetchbeforestartup.forEach(item => {

               if (!fetchedOk.includes(item)) {
                    dataloaded = false;
               }
          });


          return dataloaded;

     },
	loggedinchanges(){

		return this.active.loggedin;

	},
	gigIdChanges(){

		return this.active.gigId;
	},
	bandIdChanges(){

		return this.active.bandId;
	},


	// Als een hoofpanel wordt dichtgemaakt, de panels eronder ook dichtmaken
	panel1Changes(){

		return this.active.panel1;
	},
	panel2Changes(){

		return this.active.panel2;
	},
	panel3Changes(){

		return this.active.panel3;
	},

	panel4Changes(){

		return this.active.panel4;
	},






},

watch: {

	loggedinchanges(){

		// We zijn ingelogd, we gaan eea laden.

		this.$store.dispatch('fetchUser');

		this.$store.dispatch('fetchGigs');

		this.$store.dispatch('fetchBands');

		this.$store.dispatch('fetchTranslations');
	},

	gigIdChanges(){


		this.$store.dispatch('setGig');



	},
	bandIdChanges(){


		this.$store.dispatch('setBand');

	},
	panel3Changes(){

		this.$setActiveProperty('panel4','');
	},
	panel2Changes(){

		this.$setActiveProperty('panel3','');
	},
	panel1Changes(){

		this.$setActiveProperty('panel2','');
	},
	panel4Changes(){

		this.$setActiveProperty('panel5','');
	}



},
created(){


	if (this.config.debug==1){ console.log('===================================================================='); }

	this.checklogin();



}
}
</script>




<style>

</style>
