<template>

	<transition name="slide-up">

		<div class="panel"  v-if="this.active.panel1=='AppointmentHome'" >
			
			<div class="debug">AppointmentHome</div>

			<div class="header">
				<a class="right" @click="$setActiveProperty('panel1', '')"><DownIcon/></a>
				<div class="title">Appointment</div>
			</div>


			<div class="content" v-if="gig"> 


                    <div v-if="!isOwner" class="mb8 bold big">


                       {{ gig.user.name }}


                    </div>

	


                    <div :class="['formgroup',editing]">
                    <div class="label">Titel</div>
                    <div class="inputgroup">
                         <input class="w100" v-model="localgig.venue" type="text" :disabled="!this.active.isEditing">
                    </div>
                 
                    </div>

                    
                    <table :class="['dates',editing]">

                         <tr>

                              <td colspan="5">Start</td>
                         </tr>

                         <tr>
                              
                              <td ><div class="label">day</div></td>
                              <td><div class="label">month</div></td>
                              <td><div class="label">year</div></td>
                              <td><div class="label">hour</div></td>
                              <td><div class="label">minute</div></td>

                         </tr>
                         <tr>
                       
                         <td class="two"><input  v-model="startDay" @input="day = handleInput($event.target.value, 2)" type="text" placeholder="" :disabled="!this.active.isEditing"></td>
                         <td class="two"><input  v-model="startMonth" @input="month = handleInput($event.target.value, 2)" type="text" placeholder="" :disabled="!this.active.isEditing"></td>
                         <td class="four"><input  v-model="startYear" @input="year = handleInput($event.target.value, 4)" type="text" placeholder="" :disabled="!this.active.isEditing"></td>
                         <td class="two"><input  v-model="startHour" @input="hour = handleInput($event.target.value, 2)" type="text" placeholder="" :disabled="!this.active.isEditin"></td>
                         <td class="two"><input  v-model="startMinute" @input="minute = handleInput($event.target.value, 2)" type="text" placeholder="" :disabled="!this.active.isEditin" ></td>
                         </tr>

                         <tr>

                              <td colspan="5">End</td>
                         </tr>

                         <tr>
                              
                              <td ><div class="label">day</div></td>
                              <td><div class="label">month</div></td>
                              <td><div class="label">year</div></td>
                              <td><div class="label">hour</div></td>
                              <td><div class="label">minute</div></td>

                         </tr>

                         <tr>
                      
                         <td><input  v-model="endDay" @input="day = handleInput($event.target.value, 2)" type="text" placeholder="" :disabled="!isEditing"></td>
                         <td><input v-model="endMonth" @input="month = handleInput($event.target.value, 2)" type="text" placeholder="" :disabled="!isEditing"></td>
                         <td><input  v-model="endYear" @input="year = handleInput($event.target.value, 4)" type="text" placeholder="" :disabled="!isEditing"></td>
                         <td><input v-model="endHour" @input="hour = handleInput($event.target.value, 2)" type="text" placeholder="" :disabled="!isEditing"></td>
                         <td><input  v-model="endMinute" @input="minute = handleInput($event.target.value, 2)" type="text" placeholder="" :disabled="!isEditing"></td>
                         </tr>

                    </table>
                                                       
		

		
                    <a class="button bottomright" v-if="this.active.isEditing && isOwner" @click="submit">Save</a>


                    <a class="button bottomright" v-if="!this.active.isEditing && isOwner" @click="$setActiveProperty('isEditing',true)">Edit</a>


                    <a class="button bottomleft" v-if="this.active.isEditing && isOwner" @click="deleteClickHandler()">Delete</a>

			</div>

		


		</div><!--panel-->

	</transition>

</template>


<script>


import { mapState } from 'vuex';
import axios from 'axios';

import DownIcon from '@/components/common/icons/DownIcon.vue';

export default{

data(){
     return {

          localgig: null,
          startDay: '',
          startYear: '',
          startMonth: '',
          startHour: '',
          startMinute: '',
          endDay: '',
          endYear: '',
          endMonth: '',
          endHour: '',
          endMinute: '',
          venue: '',

     }
},

components: {

     DownIcon


},
computed:{

	...mapState(['active','gig','translate','config','user']),


     isOwner(){

          if (this.user.id==this.gig.user.id){


               return true;

          } else {


               return false;
          }


     },


     editing(){


          if (this.active.isEditing){
               
               return 'editing'; 
          } else {

               return '';
          }


     },



},



methods: {


     deleteClickHandler(){


          this.$setActiveProperty('isEditing',false);

          this.$setActiveProperty('panel2','AppointmentHomeDelete');

     },


 

     submit(){

          if (this.config.debug==1){ console.log('updateGig'); }


		this.isEditing == false;

          var post = {};

          post.task = 'updateGig';
          post.usertoken = this.active.usertoken;
          post.gigId = this.localgig.id;
          post.venue = this.localgig.venue;
          post.start = this.formatDateTime(this.startYear, this.startMonth, this.startDay, this.startHour, this.startMinute)
          post.end = this.formatDateTime(this.endYear, this.endMonth, this.endDay, this.endHour, this.endMinute)


          this.$setActiveProperty('loading',true);

          axios.post(this.config.apiserver + 'gigs', post )

          .then(response => {

               console.log(response.data);
               

               this.isEditing = false;
               this.$setActiveProperty('loading',false);
               this.$store.dispatch('fetchGigs');

               
          


          })
          .catch(error => {
               if (error.response) {

               console.log(error.response.data);

               } else if (error.request) {

               console.log(error.request);
               } else {

               console.log('Error', error.message);
               }
          });





     },


	getmonth(month){

		if (month==1){ return this.translate.JAN_SHORT[this.active.language]; }
		if (month==2){ return this.translate.FEB_SHORT[this.active.language]; }
		if (month==3){ return this.translate.MAR_SHORT[this.active.language]; }
		if (month==4){ return this.translate.APR_SHORT[this.active.language]; }
		if (month==5){ return this.translate.MAY_SHORT[this.active.language]; }
		if (month==6){ return this.translate.JUN_SHORT[this.active.language]; }
		if (month==7){ return this.translate.JUL_SHORT[this.active.language]; }
		if (month==8){ return this.translate.AUG_SHORT[this.active.language]; }
		if (month==9){ return this.translate.SEP_SHORT[this.active.language]; }
		if (month==10){ return this.translate.OCT_SHORT[this.active.language]; }
		if (month==11){ return this.translate.NOV_SHORT[this.active.language]; }
		if (month==12){ return this.translate.DEC_SHORT[this.active.language]; }

		return month;
	},

     enforceNumeric(value) {
          // Verwijder alles dat geen cijfer is
          return value.replace(/[^\d]/g, '');
     },
     enforceMaxLength(value, maxLength) {
          // Beperk de waarde tot de maximale lengte
          return value.slice(0, maxLength);
     },
     handleInput(value, maxLength) {
          let result = this.enforceNumeric(value);
          return this.enforceMaxLength(result, maxLength);
     },
     formatDateTime(year, month, day, hour, minute) {
          // Creëer een Date object
          var second = 0;

          const date = new Date(year, month - 1, day, hour, minute, second);

          // Formatteer de datum en tijd
          const formattedDate = [
          date.getFullYear(),
          ('0' + (date.getMonth() + 1)).slice(-2),
          ('0' + date.getDate()).slice(-2)
          ].join('-');

          const formattedTime = [
          ('0' + date.getHours()).slice(-2),
          ('0' + date.getMinutes()).slice(-2),
          ('0' + date.getSeconds()).slice(-2)
          ].join(':');

          // Combineer de datum en tijd tot één string
          return formattedDate + ' ' + formattedTime;
     },



},

watch: {

     'active.gigId': {
		handler() {

			// Ik maak een kopie omdat ik de data naar de api wil sturen en de api de store wil laten updaten.


               if (this.gig) {


                    this.localgig = JSON.parse(JSON.stringify(this.gig));

                    if (this.localgig) {

                         var date = new Date(this.localgig.start);
                         this.startYear = date.getFullYear();  
                         this.startMonth = date.getMonth() + 1;
                         this.startDay = date.getDate(); 
                         this.startHour =  date.getHours(); 
                         this.startMinute = date.getMinutes();


                         date = new Date(this.localgig.end);
                         this.endYear = date.getFullYear();  
                         this.endMonth = date.getMonth() + 1;
                         this.endDay = date.getDate(); 
                         this.endHour =  date.getHours(); 
                         this.endMinute = date.getMinutes();


                    }





               }
			

         



               
			
		},
		immediate: true 
		}
	},
}
</script>

<style scoped>



table .two{
width: 16%;

}

table .four{
width: 32%;

}

</style>
