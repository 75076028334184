<template>

	<transition name="slide-up">

		<div class="panel autoheight boxshadow" v-if="this.active.panel5=='UserHomeBandsBandStatusesCreate'" >

			<div class="debug">UserHomeBandsBandStatusesCreate</div>

			<div class="header">
				<div class="title">New Status</div>
				<a class="right" @click="$setActiveProperty('panel5', '')"><DownIcon/></a>
			</div>

			<div class="content ">


				<div class="formgroup">
				<div class="label">New Status</div>
				<input v-model="status" type="text" >
				</div>

				<br><br><br><br>

				
				<a class="button bottomright" @click="submit">Add Status</a>

				<a class="button ghost bottomleft" @click="$setActiveProperty('panel5', '')">Cancel</a>

			</div>



		</div>

	</transition>

</template>


<script>
import axios from 'axios';
import { mapState } from 'vuex';
import DownIcon from '@/components/common/icons/DownIcon.vue';


export default{

data(){
     return {
		status: '',
     }
},

components: {
	DownIcon
},

methods: {

	async submit() {

		var post = {};

		post.task = 'createStatus';
		post.usertoken = this.active.usertoken;
		post.name = this.status;
		post.bandId = this.active.bandId;


		axios.post(this.config.apiserver + 'statuses', post )

		.then(() => {

			this.$store.dispatch('fetchBands');
			

			this.$setActiveProperty('panel5','');
			this.status = '';


		})
		.catch(error => {
		console.error(error);



		});
	},


},

computed:{

	...mapState(['active','bands','config']),




},




watch: {



},
}
</script>

<style scoped>

</style>
