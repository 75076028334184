<template>

	<transition name="slide-up" v-if="gig">

		<div class="panel" v-if="this.active.panel1=='GigHome'" >
			
			<div class="debug">GigHome</div>

			<div class="header">
				<a class="right" @click="$setActiveProperty('panel1', '')"><DownIcon/></a>

				<div class="title"> 


					<div v-if="gig.numberofdays==1">

					<span class="uppercase ">{{ gig.dateStart.date }} {{ gig.dateStart.monthNameLong }} {{ gig.dateStart.time }}-{{ gig.dateEnd.time }}</span> <span class=" small fr"></span></div>

					<div>   

					<div v-if="gig.numberofdays>1" class="nowrap">

					<span class="uppercase ">{{ gig.dateStart.date }} {{ gig.dateStart.monthNameLong }} {{ gig.dateStart.time }} - {{ gig.dateEnd.date }} {{ gig.dateEnd.monthNameLong }}  {{ gig.dateEnd.time }}</span></div>

					</div>   

				</div>
			
			
			</div>


			<div v-if="gig">


				<div class="header2">


						<div>
							<span class="bold big">{{gig.band.name}} <span v-if="gig.userstatus=='admin'" class="badge">admin</span></span>
							
						</div>

						<div class="">
						
							<span class="big">{{gig.venue}}, {{gig.city}}</span>
						</div>


						<div class=" ">
							<span class="">{{gig.type.name}}</span>
							<span class= "fr"> {{gig.status.name}} </span>

						</div>

			
				
						
					
				</div>

					<div class="slidemenu-container">

						<div class="slidemenu">


							<div v-if="userGigStatus=='invited'" class="item" @click="gotoscreen(0)" :class="{ active: this.active.gigScreen === screensIndex }" >
								
								<div class="text">

									{{$translate('GIGHOME_AVAILABILTY')}}
							
								</div>
							</div>

							<div class="item" @click="gotoscreen(1)" :class="{ active: this.active.gigScreen ===  screensIndex + 1  }" >
								<div class="text">
									{{$translate('GIGHOME_INFO')}}
								</div>
							</div>

							<div class="item" @click="gotoscreen(2)" :class="{ active: this.active.gigScreen === screensIndex + 2 }">
								<div class="text">
									{{$translate('GIGHOME_LOCATION')}}
								</div>

							</div>


							<div class="item" @click="gotoscreen(3)" :class="{ active: this.active.gigScreen === screensIndex + 3 }">
								<div class="text">
									{{$translate('GIGHOME_MUSICIANS')}}
								</div>

							</div>

							<div class="item" @click="gotoscreen(4)" :class="{ active: this.active.gigScreen === screensIndex + 4 }">
								<div class="text">
									{{$translate('GIGHOME_NOTES')}}
								</div>

							</div>


							<div class="item" @click="gotoscreen(5)" :class="{ active: this.active.gigScreen === screensIndex + 5 }">
								<div class="text">
									{{$translate('GIGHOME_CHAT')}}
								</div>

							</div>

							<div v-if="gig.userstatus=='admin'" class="item" @click="gotoscreen(6)" :class="{ active: this.active.gigScreen === screensIndex + 6 }">
								<div class="text">
									{{$translate('GIGHOME_CHECKLIST')}} <span v-if="numberofunchecked>0" class="numberofunchecked">{{numberofunchecked}}</span>
								</div>

							</div>



			

						</div>
					</div>	

					<swiper  :onSwiper="setScreensSwiper" @slideChange="updateScreens" slidesPerView="1" spaceBetween="50" class="agenda-swiper" :initialSlide="0">


						<swiper-slide v-if="userGigStatus=='invited'">
							<GigHomeAnswer/>	
						</swiper-slide>


						<swiper-slide>
							<GigHomeInfo/>	
						</swiper-slide>

						<swiper-slide>
							<GigLocation />
						</swiper-slide>

		

						<swiper-slide>
							<GigMembers />
						</swiper-slide>


						<swiper-slide>
							<GigHomePrivatenotes />
						</swiper-slide>

						<swiper-slide>
							<GigHomeChat />
						</swiper-slide>


						<swiper-slide>
							<GigHomeChecklist />
						</swiper-slide>

					



					</swiper>




			</div>

			


		</div><!--panel-->

	</transition>

</template>


<script>
import GigHomeAnswer from '@/components/gig/GigHomeAnswer.vue';
import GigHomeChat from '@/components/gig/GigHomeChat.vue';
import GigHomeInfo from '@/components/gig/GigHomeInfo.vue';
import GigLocation from '@/components/gig/GigLocation.vue';
import GigMembers from '@/components/gig/GigMembers.vue';
import GigHomePrivatenotes from '@/components/gig/GigHomePrivatenotes';
import GigHomeChecklist from '@/components/gig/GigHomeChecklist';

import DownIcon from '@/components/common/icons/DownIcon.vue';

import { Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';

import { mapState } from 'vuex';

export default{

data(){
     return {

     }
},

components: {

	Swiper,
	SwiperSlide,
	GigHomeInfo,
	GigLocation,
	GigMembers,
	GigHomeAnswer,
	GigHomeChat,

	GigHomePrivatenotes,
	GigHomeChecklist,
	DownIcon


},
computed:{

	...mapState(['active','gig','translate','user','band']),

	numberofunchecked(){

		var checklists = JSON.parse(this.gig.checklists);

		if (checklists){
			return checklists.filter(item => item.value === false).length;
		} else {
			return 0		
		}

	},
	userGigStatus(){

		const user = this.gig.musicians.find(user => user.id === this.user.id);
      
		return user ? user.pivot.status : null;

	},
	screensIndex(){

		if (this.userGigStatus=="yes" || this.userGigStatus=="no"){


			return -1;

		} else {

			return 0;

		}


	}


},



methods: {

	setScreensSwiper(swiper) {
		this.screensswiper = swiper;

	},

	updateScreens(swiper){

		const activeIndex = swiper.activeIndex;
		this.$setActiveProperty('gigScreen', activeIndex);

	},


	gotoscreen(screen){

		

		if (this.userGigStatus =='yes' || this.userGigStatus =='no'){

	
			screen = screen-1;
		

		}

		this.screensswiper.slideTo(screen);

	},
	getmonth(month){

		if (month==1){ return this.translate.JAN_SHORT[this.active.language]; }
		if (month==2){ return this.translate.FEB_SHORT[this.active.language]; }
		if (month==3){ return this.translate.MAR_SHORT[this.active.language]; }
		if (month==4){ return this.translate.APR_SHORT[this.active.language]; }
		if (month==5){ return this.translate.MAY_SHORT[this.active.language]; }
		if (month==6){ return this.translate.JUN_SHORT[this.active.language]; }
		if (month==7){ return this.translate.JUL_SHORT[this.active.language]; }
		if (month==8){ return this.translate.AUG_SHORT[this.active.language]; }
		if (month==9){ return this.translate.SEP_SHORT[this.active.language]; }
		if (month==10){ return this.translate.OCT_SHORT[this.active.language]; }
		if (month==11){ return this.translate.NOV_SHORT[this.active.language]; }
		if (month==12){ return this.translate.DEC_SHORT[this.active.language]; }

		return month;
	},



},

watch: {

	// Als je het panel opent, altijd op het eerste scherm beginnen.

	'active.panel1': {
		handler() {

			this.$setActiveProperty('gigScreen',0);
			
		},
		immediate: true 
	}
	



},
}
</script>

<style scoped>

.header{

	background: var(--blauw);
	color: #fff;
}


.header2{
padding: 0px 18px;
overflow: hidden;
background: var(--blauw);
color: #fff;
}

.down{

fill: #fff;
}

.numberofunchecked{
background: var(--blauw);
font-weight: 700;
color: #fff;
padding-top: 0px;
font-size: 10px;
line-height: 16px;
border-radius: 50%;
width: 16px;
height: 16px;
display: inline-block;
text-align: center;
box-sizing: border-box;
position: relative;
top: -6px;
}


.swiper {
	height: calc(100vh - 180px);
     overflow: hidden;
}

.date{
padding-top: 10px;
}

.badge{
	background: #fff;
	color: var(--blauw);
}
</style>
