<template>

	<transition name="slide-right">

		<div class="panel" v-if="this.active.panel1=='UserHome'" >

		<div class="debug">UserHome</div>

		<div class="header">
		
			<div class="title center">{{translate['SETTINGS'][active.language]}}</div>
			<a class="left" @click="$setActiveProperty('panel1', '')"><LeftIcon/></a>
		</div>



		<div class="content">


			<a class="button grey w100" @click="$setActiveProperty('panel2', 'UserHomeAccount')">Account <RightIcon/></a>

			<a class="button grey w100" @click="$setActiveProperty('panel2', 'UserHomeBands')">Bands <RightIcon/></a>

		</div>





		</div>

	</transition>

</template>


<script>

import { mapState } from 'vuex';


import RightIcon from '@/components/common/icons/RightIcon.vue';
import LeftIcon from '@/components/common/icons/LeftIcon.vue';


export default{

data(){
     return {

     }
},

components: {

	RightIcon,
	LeftIcon
},
computed:{

	...mapState(['active','translate','user']),




},



methods: {


},

watch: {



},
}
</script>

<style scoped>

</style>
