<template>

	<transition name="slide-up">

		<div class="panel autoheight boxshadow" v-if="this.active.panel3=='UserHomeBandsCreate'" >

			<div class="debug">UserHomeBandsCreate</div>

			<div class="header">


				<div class="title"><h2>New Band</h2></div>

				<div class="right"><a class="close" @click="$setActiveProperty('panel3', '')"><DownIcon/></a></div>

				
			</div>


			<div class="content">

	
				<div class="formgroup">
				<div class="label">Name</div>
				<input v-model="name" type="text">
				</div>

				<a class="button fr" @click="submit">Add Band</a>


			</div>


		</div>

	</transition>

</template>


<script>

import axios from 'axios';

import { mapState } from 'vuex';
import DownIcon from '@/components/common/icons/DownIcon.vue';

export default{

data(){
     return {
		name: ''
     }
},

components: {
	DownIcon
},
computed:{

	...mapState(['active','config']),


},



methods: {


	async submit() {

		var post = {};

		post.task = 'createBand';
		post.usertoken = this.active.usertoken;
		post.name = this.name;
		post.language = this.active.language;

		// console.log(post);

		axios.post(this.config.apiserver + 'bands', post )

		.then(response => {

			console.log(response.data);


			this.$store.dispatch('fetchBands');
			this.$setActiveProperty('panel3','');


		})
		.catch(error => {
		console.error(error);



		});
	},


},

watch: {



},
}
</script>

<style scoped>

</style>
