<template>

	<transition name="slide-up">

		<div class="panel autoheight"  v-if="this.active.panel2=='AppointmentHomeDelete'" >
			
			<div class="debug">AppointmentHomeDelete</div>

			<div class="header">
				<a class="right" @click="$setActiveProperty('panel1', '')"><DownIcon/></a>
				<div class="title"></div>
			</div>


			<div class="content centertext" v-if="gig">


                    Weet je zeker dat je deze afspraak wil deleten?

                                                       
		

		
                    <a class="button bottomright" @click="submit">Delete</a>

                    <a class="button bottomleft" @click="this.$setActiveProperty('panel2','');">Cancel</a>


                    

			</div>

		


		</div><!--panel-->

	</transition>

</template>


<script>

import DownIcon from '@/components/common/icons/DownIcon.vue';
import { mapState } from 'vuex';
import axios from 'axios';

export default{

data(){
     return {

          localgig: null,


     }
},

components: {

     DownIcon


},
computed:{

	...mapState(['active','gig','translate','config']),



},



methods: {

     submit(){

          if (this.config.debug==1){ console.log('updateGig'); }

          this.$setActiveProperty('isEditing',false);

          var post = {};

          post.task = 'updateGig';
          post.usertoken = this.active.usertoken;
          post.gigId = this.gig.id;
          post.state = 0;
   


          this.$setActiveProperty('loading',true);

          axios.post(this.config.apiserver + 'gigs', post )

          .then(response => {

               console.log(response.data);

               this.$setActiveProperty('loading',false);
               this.$setActiveProperty('panel2','');
               this.$setActiveProperty('panel1','');
               this.$store.dispatch('fetchGigs');

     
          


          })
          .catch(error => {
               if (error.response) {

               console.log(error.response.data);

               } else if (error.request) {

               console.log(error.request);
               } else {

               console.log('Error', error.message);
               }
          });





     },




},

watch: {

     'active.gigId': {
		handler() {

			// Ik maak een kopie omdat ik de data naar de api wil sturen en de api de store wil laten updaten.
			// this.localgig = JSON.parse(JSON.stringify(this.gig));


			
		},
		immediate: true 
		}
	},
}
</script>

<style scoped>





</style>
