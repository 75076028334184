<template>



	<div class="screen" >



          <span v-for="checklist in checklists" :key="checklist.id">
              
               
           
          

               <div class="formgroup">

                    <div class="inputgroup">

   
                         <span v-if="!isEditing" class="mr8">

                              <span v-if="checklist.value">
                                   <CheckedIcon/>
                              </span>

                   
                              <span v-else>
                                   <UncheckedIcon/>
                              </span>     

                         </span>

                         <span class="name">
                              {{checklist.name }} 

                         </span>

                         <span v-if="isEditing" class="right">

                              <Toggle  :value="checklist.value" @change="updatechecklist($event, checklist.id)"/>
                    

                         </span>

                    </div>


          
               </div>
          </span>






	</div><!-- screen -->

	<div class="footer">

		<a class="button bottomright" v-if="!isEditing" @click="isEditing = true">Edit</a>
		
		<a class="button bottomleft ghost" v-if="isEditing" @click="isEditing = false">Annuleren</a>
	
		<a class="button bottomright" v-if="isEditing" @click="submit">Save</a>



	</div>












</template>


<script>



import { mapState } from 'vuex';
import axios from 'axios';
import Toggle from '@vueform/toggle'
import CheckedIcon from '@/components/common/icons/CheckedIcon.vue';
import UncheckedIcon from '@/components/common/icons/UncheckedIcon.vue';

export default{

data(){
     return {
		localgig: null,
		isEditing: false,
          checklists: [],
     }
},

components: {

     Toggle,
     CheckedIcon,
     UncheckedIcon

},
computed:{

	...mapState(['active','gig','band','config']),

	editing(){


		if (this.isEditing){
			
			return 'editing'; 
		} else {

			return '';
		}



	},



},



methods: {

     createChecklists(){

          try {
          var checklists = JSON.parse(this.band.checklists);
          } catch (error) {
          checklists = [];
          }

          try {
               var gigchecklists = JSON.parse(this.localgig.checklists);
          } catch (error) {
               gigchecklists = null;
          }





          if (gigchecklists) {



               checklists = checklists.map(checklist => {


               const gigchecklist = gigchecklists.find(element => element.name === checklist.name);

               if (gigchecklist){
               return { ...checklist, value: gigchecklist.value };
               } else {
               return { ...checklist, value: false};
               }

               });

          } 


 

          this.checklists = checklists;



     },


     updatechecklist(event, id) {
          const updatedValue = event; 

          const checklist = this.checklists.find(checklist => checklist.id === id);

          if (checklist) {
               
               checklist.value = updatedValue;

 

          }

     
     },



	submit(){

		if (this.config.debug==1){ console.log('updateGig'); }

		this.isEditing = false;

		var post = {};

		post.task = 'updateGig';
          post.usertoken = this.active.usertoken;
          post.gigId = this.gig.id;
		post.checklists = JSON.stringify(this.checklists);


		// console.log(post);

		this.$setActiveProperty('loading',true);

          axios.post(this.config.apiserver + 'gigs', post )

          .then(() => {

     

			this.$setActiveProperty('loading',false);
			this.$store.dispatch('fetchGigs');
             


          })
          .catch(error => {
               if (error.response) {

               console.log(error.response.data);

               } else if (error.request) {

               console.log(error.request);
               } else {

               console.log('Error', error.message);
               }
          });





	}


},

watch: {
	
	'gig.updated_at': {
		handler() {

			// Ik maak een kopie omdat ik de data naar de api wil sturen en de api de store wil laten updaten.
			this.localgig = JSON.parse(JSON.stringify(this.gig));


               this.createChecklists();


			
		},
		immediate: true 
		}
},
}




</script>

<style scoped>

.checked{

width: 32px;
height: 32px;

fill: var(--blauw);
}

.unchecked{

width: 26px;
height: 26px;
position: relative;
left: 3px;
margin-right: 5px;
fill: var(--blauw);

}

.name{
     position: relative;
     top: -2px;
}

.screen{
	height: calc(100dvh - 210px) ;


}

</style>
