<template>



	<div class="screen" >


		<div class="bold big mb8">{{$translate('GIGMEMBERS_INVITED')}}</div>


		<table class="form mb32" >


	
			<tr v-for="user in this.gig.musicians" :key="user.id">

				<td>{{user.email }}</td>
				<td class="tar">{{user.pivot.status}}</td>
			

			</tr>

		</table>


		<div v-if="band.role=='owner'">

			<div   class="bold big mb8">{{$translate('GIGMEMBERS_NOTINVITED')}}</div>


			<table class="form">


				<tr v-for="user in notinvited" :key="user.id">

					<td>{{user.email }}</td>
				
					<td class="tar"><a class="button" @click="submit(user.email)">Send invitation</a></td>

				</tr>

			</table>


			<textarea v-model="text" type="text" placeholder="Additional text bij invite"></textarea>



		</div>


	

			



	</div>



</template>


<script>


import axios from 'axios';
import { mapState } from 'vuex';

export default{

data(){
     return {
		text: '',
     }
},

components: {



},
computed:{

	...mapState(['active','band','gig','config']),


	notinvited(){


		try {
			return this.band.users.filter(user => !this.gig.musicians.some(removeUser => removeUser.email === user.email));

		} catch (error) {
			return null;
		}


		




	}




},



methods: {

	async submit(email) {

	var post = {};

	post.task = 'inviteUser';
	post.usertoken = this.active.usertoken;
	post.email = email;
	post.language = this.active.language;
	post.gigId = this.gig.id;
	post.bandId = this.band.id;

	this.$setActiveProperty('loading',true);

	axios.post(this.config.apiserver + 'users', post )



	.then(response => {


		this.$setActiveProperty('loading',false);

		console.log(response.data);


		this.$store.dispatch('fetchGigs');
	


	})
	.catch(error => {
	console.error(error);



	});
	},


},

watch: {



},
}
</script>

<style scoped>




</style>
