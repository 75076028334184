<template>

   
     <div class="overlay nul"  :class="{ 'open': show }" @click="$setActiveProperty('panel1', '')">

     
          

     </div>


</template>

<script>

import { mapState } from 'vuex';

export default{

data(){
     return {

     }
},

components: {

},
computed:{

	...mapState(['active']),

     show(){

     var panel = this.active.panel1;

     if (panel){
          return true;
     } else {

          return false;
     }


     }





},



methods: {


},

watch: {



},
}
</script>

<style scoped>

</style>
