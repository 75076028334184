function getWeekNumber() {


     var date = new Date();
  // Kopieer de datum om de originele datum niet te veranderen
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  // Stel de dag van de week in op maandag (0 voor zondag, 1 voor maandag, enz.)
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
  // Bereken de start van het jaar
  const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  // Bereken het weeknummer
  const weekNo = Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
  return weekNo;
}

export default {

     config:{
          apiserver: 'https://api.maxbandapp.com/api/',
          debug: 1,
          fetchbeforestartup: ['gigs','translations','user'],

     },

     active: {

          currentYear: new Date().getFullYear(),
          currentMonth: new Date().getMonth()+ 1,
          currentDay: new Date().getDate(),
          currentWeek: getWeekNumber(),
          language: 'nl',
		loggedin: false,
		gigId: '',
		gigScreen: 0,
          filterShowShared: true,
          loginstate: 'login',
          bandId: 0,
		isEditing: false,
	


     },
     gigs:{},
     gig:{},
     fetched: [],
	gigchats: {},
	bandchats: {},
	


};
